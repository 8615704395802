import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  select {
    background: #1a1919;
    color: #fff;
    border: none;
    min-height: 43px;
  }
`