import styled from 'styled-components'

interface StatusSpanParams {
  readonly color?: any
}

export const StatusSpan = styled.div<StatusSpanParams>`
  background: ${(props: any) => props.color ? props.color : 'transparent'};
  padding: 4px;
  font-size: 10px;
  border-radius: 15px;
  text-align: center;
  color: #000;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 150px;
`

export const PaymentSpan = styled.div`
  max-width: 150px;
  display: flex;

  img {
    max-width: 60px !important;
    margin-right: 10px;
  }
`

export const InputStyle = styled.input`
  background: #080808;
  border: 1px solid #5d5c5c;
  border-radius: 10px;
  color: #ffffff;
  font-size: 15px;
  text-align: left;
  max-width: 60px;
  text-align: center;
  cursor: pointer;
`

export const ButtonStyle = styled.button`
  font-size: 12px;
  padding: 5px;
  background: #40c535;
  margin-left: 10px;
  border-radius: 20px;
`