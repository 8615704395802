/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Wrapper, Content, HeaderPage, SwitchContent, ItemsList } from './styles';
import api from '../../services/api';
import { Loading } from '../Loading';
import { AuthContext } from '../../contexts/auth';
import { ButtonDashboard } from '../ButtonDashboard';
import { MdKeyboardArrowLeft, MdDoneAll, MdCheck, MdClose } from 'react-icons/md';
import { InputDashboard } from '../InputDashboard';
import { InputGroupDashboard } from '../InputGroupDashboard';
import history from '../../history'
import useQuery from '../../utils/queryParams'
import Switch from 'react-switch'
import numberToReal from '../../utils/numberToReal';

export function OrdersDashboardEdit() {
  const { handleLogout } = React.useContext(AuthContext)

  let query = useQuery()
  const id = query.get('id')
  const [data, setData] = useState<any>({})
  const [dataToBody, setDataToBody] = useState<any>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getOrder() {
      setLoading(true)
      const response = await api.get(`/admin/orders/list-id/${id}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      if (response && response.data) {
        const createdAt = parseISO(response.data.created_at)
        const createdAtZoned = zonedTimeToUtc(createdAt, 'America/Sao_Paulo')
        const date = format(createdAtZoned, 'dd/MM/yyyy hh:mm')

        setData({
          full_name: response.data.full_name,
          email: response.data.email,
          address: response.data.address,
          number: response.data.number,
          phone: response.data.customer.phone,
          cep: response.data.cep,
          cpf: response.data.cpf,
          district: response.data.district,
          city: response.data.city,
          state: response.data.uf,
          is_processed: response.data.is_processed,
          amount: numberToReal(response.data.amount ?? 0),
          amount_shipping: numberToReal(response.data.amount_shipping ?? 0),
          option_shipping: response.data.option_shipping,
          code_shipping: response.data.code_shipping,
          user: response.data.user,
          customer: response.data.customer,
          coupon: response.data.coupon,
          stock_type: response.data.stock_type,
          is_conditional: response.data.is_conditional,
          items: response.data.items,
          status: response.data.status,
          status_shipping: response.data.status_shipping,
          status_detail: response.data.status_detail,
          notes: response.data.notes,
          additional: response.data.additional,
          whatsapp_order: decodeURI(response.data.whatsapp_order),
          created_at: date,
        })

        setDataToBody({
          ...dataToBody,
          is_processed: response.data.is_processed,
          is_conditional: response.data.is_conditional,
        })
        
      }

      setLoading(false)
    }

    if (id !== null) {
      getOrder();
    }
  }, [])

  async function handleSave() {
    setLoading(true)

    const response = await api.put(`/admin/orders/${id}`, {...dataToBody, status: dataToBody.is_processed ? 'payd' : data.status}).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    setLoading(false)

    if (response && response.status === 200) history.push('/dashboard/orders')
  }

  return (
    <Wrapper>
      <HeaderPage>
        <div>
          <h3 onClick={() => history.push('/dashboard/orders')} ><MdKeyboardArrowLeft size={30}/> Pedidos</h3>
        </div>
        <div>
          <ButtonDashboard onClick={() => handleSave()} color='#12a93f' text='Salvar' icon={<MdDoneAll size={20} />} />
        </div>
      </HeaderPage>
      <Content>
        {loading ? <Loading /> :
          <> 
            <InputGroupDashboard>
              <textarea disabled={true} placeholder='Pedido Completo' defaultValue={data.whatsapp_order && data.whatsapp_order.split('text=')[1]} rows={10} onChange={(e: any) => setDataToBody({...dataToBody, whatsapp_order: e.target.value })}  />
            </InputGroupDashboard>
        
            <h5>Informações do Vendedor</h5>
            <InputGroupDashboard>
              <InputDashboard placeholder='Código' defaultValue={data.notes} disabled/>
              <InputDashboard placeholder='Nome' defaultValue={data.user ? data.user.name : ''} disabled/>
              <InputDashboard placeholder='E-mail' defaultValue={data.user ? data.user.email : ''} disabled/>
            </InputGroupDashboard>
            <h5>Informações do Cliente</h5>
            <InputGroupDashboard>
              <InputDashboard placeholder='Nome' defaultValue={data.customer ? data.customer.full_name : ''} disabled/>
              <InputDashboard placeholder='E-mail' defaultValue={data.customer ? data.customer.email : ''} disabled/>
              <InputDashboard placeholder='Telefone' defaultValue={data.customer ? data.customer.phone : ''} disabled/>
            </InputGroupDashboard>
            <h5>Informações da Entrega</h5>
            <InputGroupDashboard>
              <InputDashboard placeholder='Nome' defaultValue={data.customer ? data.customer.full_name : ''} disabled/>
              <InputDashboard placeholder='E-mail' defaultValue={data.customer ? data.customer.email : ''} disabled/>
              <InputDashboard placeholder='CPF' defaultValue={data.customer ? data.customer.cpf : ''} disabled/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard placeholder='Endereço' defaultValue={data.address} disabled/>
              <InputDashboard placeholder='Complemento' defaultValue={data.additional} disabled/>
              <InputDashboard placeholder='Número' defaultValue={data.number} disabled/>
              <InputDashboard placeholder='CEP' defaultValue={data.cep} disabled/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard placeholder='Bairro' defaultValue={data.district} disabled/>
              <InputDashboard placeholder='Cidade' defaultValue={data.city} disabled/>
              <InputDashboard placeholder='Estado' defaultValue={data.state} disabled/>
            </InputGroupDashboard>
            <h5>Informações do Pedido</h5>
            <InputGroupDashboard>
              <InputDashboard placeholder='Valor Total' defaultValue={data.amount}  disabled/>
              <InputDashboard placeholder='Valor do Frete' defaultValue={data.amount_shipping} disabled/>
              <InputDashboard placeholder='Frete Escolhido' defaultValue={data.option_shipping} disabled/>
              <InputDashboard placeholder='Código de Rastreio' defaultValue={data.code_shipping}  onChange={(e: any) => setDataToBody({...dataToBody, code_shipping: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard placeholder='Cupom Utilizado' defaultValue={data.coupon ? data.coupon.name : ''} disabled/>
              <InputDashboard placeholder='Status' defaultValue={data.status}  disabled/>
              <InputDashboard placeholder='Detalhe do Status' defaultValue={data.status_detail} disabled/>
              <InputDashboard placeholder='Status do Frete' defaultValue={data.status_shipping} disabled/>
            </InputGroupDashboard>
            <h5>Itens</h5>
            <ItemsList>
              {data.items && data.items.map((item: any) => {
                return (
                  <li>
                    <span>Cód {item.code} - {item.product.name}</span>
                    <span>Qntd: <b>{item.product.qtd}</b></span>
                  </li>
                )
              })}
            </ItemsList>
            <InputGroupDashboard>
              <SwitchContent>
                <h4>Pedido Processado</h4>
                <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setDataToBody({...dataToBody, is_processed: !dataToBody.is_processed})} checked={dataToBody.is_processed} defaultChecked={data.is_processed} />
              </SwitchContent>
              <SwitchContent>
                <h4>Condicional</h4>
                <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#c1c1c1' onColor='#12a93f' onChange={(e) => setDataToBody({...dataToBody, is_conditional: !dataToBody.is_conditional})} checked={dataToBody.is_conditional} defaultChecked={data.is_conditional} />
              </SwitchContent>
              <SwitchContent>
                <InputDashboard placeholder='Data do Pedido' defaultValue={data.created_at} disabled />
              </SwitchContent>
            </InputGroupDashboard>
          </>
        }
      </Content>
    </Wrapper>
  )
}
