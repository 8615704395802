/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios'
import { Wrapper, Content, HeaderPage, SwitchContent } from './styles';
import api from '../../services/api';
import { Loading } from '../Loading';
import { AuthContext } from '../../contexts/auth';
import { ButtonDashboard } from '../ButtonDashboard';
import { MdChevronLeft, MdDeleteOutline, MdDoneAll, MdCheck, MdClose } from 'react-icons/md';
import { InputDashboard } from '../InputDashboard';
import { InputGroupDashboard } from '../InputGroupDashboard';
import history from '../../history'
import useQuery from '../../utils/queryParams'
import Switch from 'react-switch'
import { ToastContainer, toast } from 'react-toastify';

export function UsersDashboardEdit() {
  const { handleLogout } = React.useContext(AuthContext)

  let query = useQuery()
  const id = query.get('id')
  const [data, setData] = useState<any>({})
  const [dataToBody, setDataToBody] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [files, setFiles] = useState<FileList | null>(null)

  useEffect(() => {
    async function getUser() {
      setLoading(true)
      const response = await api.get(`/users/admin/${id}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      if (response && response.data) {
        setData({
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          birth_date: response.data.birth_date,
          instagram: response.data.instagram,
          tiktok: response.data.tiktok,
          x: response.data.x,
          address: response.data.address,
          school: response.data.school,
          class: response.data.class,
          address_number: response.data.address_number,
          cep: response.data.cep,
          cpf: response.data.cpf,
          rg: response.data.rg,
          points: response.data.points,
          cpf_owner: response.data.cpf_owner,
          name_owner: response.data.name_owner,
          phone_owner: response.data.phone_owner,
          city: response.data.city,
          contract_link: response.data.contract_link,
          signed: response.data.signed,
          is_approved: response.data.is_approved,
          is_complete: response.data.is_complete
        })

        setDataToBody({
          ...dataToBody,
          signed: response.data.signed,
          is_approved: response.data.is_approved,
          is_complete: response.data.is_complete,
        })
      }

      setLoading(false)
    }

    if (id !== null) {
      getUser();
    }
  }, [])

  async function handleSave() {
    setLoading(true)

    const response = await api.put(`/users/admin/${id}`, dataToBody).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    setLoading(false)

    if (response && response.status === 200) history.push('/dashboard/users')
  }

  async function handleDelete() {
    setLoading(true)
    const response = await api.delete(`/users/admin/${id}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    setLoading(false)

    if (response && response.status === 204) history.push('/dashboard/users')
  }

  async function handleUploadFile(e: FormEvent) {
    e.preventDefault()

    if (!files || files.length === 0) {
      return
    }

    const file = files[0]

    await axios.put('https://formadiamond-prod.13e4335f61b73f3a59d43db5f0360bc2.r2.cloudflarestorage.com/f7e2cb7d-c778-4694-a453-37e9c39aad75-boleto%20amanda?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=cf3d955c88959afbc9d69b08a024ae33%2F20240709%2Fauto%2Fs3%2Faws4_request&X-Amz-Date=20240709T042255Z&X-Amz-Expires=600&X-Amz-Signature=1010c85f7aa69608012f2ac65a7f3afb7b71e92111646c6f2363ac20f1daaa28&X-Amz-SignedHeaders=host&x-id=PutObject', file, {
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
  }

  return (
    <Wrapper>
      <HeaderPage>
        <div>
          <h3><MdChevronLeft size={22} onClick={() => history.push('/dashboard/users')} />Alunos</h3>
        </div>
        <div>
          <ButtonDashboard onClick={() => handleSave()} color='#12a93f' text='Salvar' icon={<MdDoneAll size={20} />} />
          {id !== null && Number(id) !== 1 ?
            confirmDelete ?
              <ButtonDashboard onClick={() => handleDelete()} color='#eb4073' text='Confirmar Exclusão' icon={<MdDoneAll size={20} />} />
              :
              <ButtonDashboard onClick={() => setConfirmDelete(true)} color='#ff0c54' text='Excluir' icon={<MdDeleteOutline size={20} />} />
            : ''
          }
        </div>
      </HeaderPage>
      <Content>
        {loading ? <Loading /> :
          <>
            <InputGroupDashboard>
              <InputDashboard placeholder='Nome' defaultValue={data.name} onChange={(e: any) => setDataToBody({...dataToBody, name: e.target.value})}/>
              <InputDashboard placeholder='E-mail' defaultValue={data.email} onChange={(e: any) => setDataToBody({...dataToBody, email: e.target.value})}/>
              <InputDashboard placeholder='CPF' defaultValue={data.cpf} onChange={(e: any) => setDataToBody({...dataToBody, cpf: e.target.value})}/>
              <InputDashboard placeholder='RG' defaultValue={data.rg} onChange={(e: any) => setDataToBody({...dataToBody, rg: e.target.value})}/>
              <InputDashboard placeholder='Telefone' defaultValue={data.phone} onChange={(e: any) => setDataToBody({...dataToBody, phone: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard placeholder='Escola' defaultValue={data.school}  onChange={(e: any) => setDataToBody({...dataToBody, school: e.target.value})}/>
              <InputDashboard placeholder='Turma' defaultValue={data.class}  onChange={(e: any) => setDataToBody({...dataToBody, class: e.target.value})}/>
              <InputDashboard placeholder='Pontos' defaultValue={data.points}  onChange={(e: any) => setDataToBody({...dataToBody, points: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard placeholder='Endereço' defaultValue={data.address}  onChange={(e: any) => setDataToBody({...dataToBody, address: e.target.value})}/>
              <InputDashboard placeholder='CEP' defaultValue={data.cep}  onChange={(e: any) => setDataToBody({...dataToBody, cep: e.target.value})}/>
              <InputDashboard placeholder='Data de Aniversário' defaultValue={data.birth_date}  onChange={(e: any) => setDataToBody({...dataToBody, birth_date: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard placeholder='Nome Responsável' defaultValue={data.name_owner}  onChange={(e: any) => setDataToBody({...dataToBody, name_owner: e.target.value})}/>
              <InputDashboard placeholder='CPF Responsável' defaultValue={data.cpf_owner}  onChange={(e: any) => setDataToBody({...dataToBody, cpf_owner: e.target.value})}/>
              <InputDashboard placeholder='Telefone Responsável' defaultValue={data.phone_owner}  onChange={(e: any) => setDataToBody({...dataToBody, phone_owner: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard placeholder='Instagram' defaultValue={data.instagram}  onChange={(e: any) => setDataToBody({...dataToBody, instagram: e.target.value})}/>
              <InputDashboard placeholder='TikTok' defaultValue={data.tiktok}  onChange={(e: any) => setDataToBody({...dataToBody, tiktok: e.target.value})}/>
              <InputDashboard placeholder='X' defaultValue={data.x}  onChange={(e: any) => setDataToBody({...dataToBody, x: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard id='link-contract' name='link-contract' placeholder='Link do Contrato' defaultValue={data.contract_link}  onChange={(e: any) => setDataToBody({...dataToBody, contract_link: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard id='password-user-forma' name='password-user-forma' type='password' placeholder='Nova Senha' defaultValue={data.password} onChange={(e: any) => setDataToBody({...dataToBody, password: e.target.value})}/>
              <InputDashboard id='passwordconf-user-forma' name='passwordconf-user-forma' type='password' placeholder='Confirmar Senha' defaultValue={data.confirmPassword} onChange={(e: any) => setDataToBody({...dataToBody, confirmPassword: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <SwitchContent>
                <h4>Contrato Assinado</h4>
                <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setDataToBody({...dataToBody, signed: !dataToBody.signed})} checked={dataToBody.signed} defaultChecked={data.signed} />
              </SwitchContent>
              <SwitchContent>
                <h4>Cadastro Completo</h4>
                <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setDataToBody({...dataToBody, is_complete: !dataToBody.is_complete})} checked={dataToBody.is_complete} defaultChecked={data.is_complete} />
              </SwitchContent>
            </InputGroupDashboard>

            {/* <form onSubmit={handleUploadFile}>
              <input type="file" name="file" onChange={e => setFiles(e.target.files)} />
              <button type='submit'>Enviar</button>
            </form>

            <a href='https://formadiamond-prod.13e4335f61b73f3a59d43db5f0360bc2.r2.cloudflarestorage.com/f7e2cb7d-c778-4694-a453-37e9c39aad75-boleto%20amanda?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=cf3d955c88959afbc9d69b08a024ae33%2F20240709%2Fauto%2Fs3%2Faws4_request&X-Amz-Date=20240709T042742Z&X-Amz-Expires=600&X-Amz-Signature=5c9fcaf9fab3028a9c1e578c4fcca8ceea81d3201f693868e6b27aab8cbfff90&X-Amz-SignedHeaders=host&x-id=GetObject' download target='blank'>Click to download</a> */}
          </>
        }
      </Content>
    </Wrapper>
  )
}
