import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.form`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 100px;

  h2 {
    display: block;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  h5 {
    svg {
      opacity: .8;
      transform: scale(1);
      animation: float 1s infinite;
  
      @keyframes float {
        0% {
          padding-top: 10px;
        }
  
        50% {
          padding-top: 2px;
        }
  
        100% {
          padding-top: 0px;
        }
      }
    }
  }
`

export const FormContent = styled.div`
  display: block;
  width: 100%;
  padding: 50px;

  h2 {
    margin-bottom: 15px;
    font-weight: 200;
    color: #1d2a36;
  }

  input {
    width: 100%;
    margin-bottom: 25px;
  }

  button {
    width: 90%;
  }

  @media (max-width: 775px) {
    width: 100%;
  }

  textarea {
    width: 100%;
    display: flex;
    padding-right: 10px;
    background-color: white;
    border-radius: 25px 25px 5px 25px;
    width: 100%;
    border: 4px solid transparent;
    padding: 15px;
    transition: 0.2s;
    outline: none;
    padding-right: 5px;

    &:hover, &:active, &:focus-visible {
      transition: 0.2s;
      border: 4px solid #d3cece;
    }
  }

  button {
    color: #ffffff;
    background: #0086ef;
    padding: 10px;
    border-radius: 50px 50px 10px 50px;
    font-size: 14px;
    margin-top: 20px;
    transition: 0.2s;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
    max-height: 45px;
    border: 3px solid #0086ef;
    transition: 0.2s;
    box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);
    
    &:hover {
      border-radius: 10px 50px 50px 50px;
      transition: 0.2s;
      box-shadow: 0px 8px 65px -6px #0086ef;
    }
  }

  span, h4 {
    display: block;
    background: #ff5c5c;
    color: #ffffff;
    font-size: 14px;
    padding: 5px;
    border-radius: 20px 20px 3px 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  h4 {
    background: rgb(4, 211, 97);
    color: #2b2b2c;
  }
`

export const FormGroup = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;
  margin-top: 50px;

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }

`

interface ButtonElementProps {
  readonly loading: boolean;
};

export const ButtonElement = styled.button.attrs((props: any) => ({
  type: 'button',
  disabled: props.loading ? true : false,
}))<ButtonElementProps>`
  color: #ffffff;
  background: #0086ef;
  padding: 10px;
  border-radius: 50px 50px 10px 50px;
  font-size: 14px;
  margin-top: 20px;
  transition: 0.2s;
  display: block;
  justify-content: right;
  width: 200px;
  margin-top: 20px;
  max-height: 45px;
  border: 3px solid #0086ef;
  transition: 0.2s;
  box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);

  opacity: ${(props: any) => props.loading ? .5 : 1};
  cursor: ${(props: any) => props.loading ? 'not-allowed' : 'pointer'};
  
  &:hover {
    border-radius: 10px 50px 50px 50px;
    transition: 0.2s;
    box-shadow: 0px 8px 65px -6px #0086ef;
  }

  svg {
    animation: ${rotate} 1s linear infinite;
  }
`

export const SellerContent = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;

  div {
    display: inline-block;

    h3 {
      font-weight: 200;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background: #0086ef;
      padding: 10px;
      border-radius: 50px 50px 10px 50px;
      font-size: 14px;
      transition: 0.2s;
      width: 200px;
      max-height: 45px;
      border: 3px solid #0086ef;
      transition: 0.2s;
      box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);
      cursor: pointer;
      margin-top: 10px;
      text-decoration: none;

      svg {
        margin-right: 5px;
      }
      
      &:hover {
        border-radius: 10px 50px 50px 50px;
        transition: 0.2s;
        box-shadow: 0px 8px 65px -6px #0086ef;
      }
    }
  }

  @media (max-width: 1085px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 867px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 578px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
