import { Wrapper, Label, Content } from './styles'

export const Select = ({ options, ...rest } : any) => {
  return (
    <>
    <Content hasError={false}>
      {rest.placeholder ? <Label>{rest.placeholder}</Label> : '' }
      <Wrapper {...rest}>
        {options && options.map((option: any) => <option value={option.value}>{option.text}</option>)}
      </Wrapper>
    </Content>
    </>
  )
};