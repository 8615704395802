import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
  margin-top: 90px;
  padding: 10px;

  h3, p {
    text-align: left;
    display: flex;
    width: 100%;
    font-weight: 200;
  }

  p {
    font-size: 14px;
    opacity: .5;
  }

  .react-tabs {
    margin-top: 20px;
  }

  .react-tabs__tab--selected {
    background: none;
    color: #fff;
    font-weight: bold;
    border-color: transparent;
    border-bottom: 4px solid #41abff;
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #41abff;
  }

  .react-tabs__tab-panel--selected {
    display: block;
    background: rgba(93 93 93 / 50%);
    border-radius: 10px;
    text-align: left;
    padding: 10px;

    h4, h5, span {
      display: flex;
      font-weight: 300 !important;
    }

    h5 {
      p {
        text-align: right;
      }
    }

    p {
      color: #fff;
      display: block;
      opacity: 1;

      small {
        opacity: 0.4;
      }
    }

    span {
      padding: 10px;
      align-items: center;
      border-right: 1px solid #484848;
      margin-right: 10px;
      padding-right: 20px;
    }
  }

  .carousel .slide img {
    border-radius: 15px;
    margin-bottom: 30px;
  }

  .carousel .control-dots {
    z-index: 0;
  }

  .react-calendar {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;
    background: rgba(93, 93, 93, 0.5);
    border: none;
    border-radius: 15px;
  }

  .react-calendar__navigation button {
    color: #fff;
  }

  .react-calendar button {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }

  .react-calendar__tile--active {
    border-radius: 20px;
  }

  .react-calendar__tile--now {
    background: #4f4f4f;
    border-radius: 20px;
  }

  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background: #4f4f4f;
    border-radius: 20px;
  }
`

export const StatiscticSecondary = styled.div`
  display: inline-block;
  background: rgb(32, 32, 36, 0.3);
  padding: 20px;
  color: #ffffff;
  border-radius: 15px 15px 3px 15px;
  justify-content: left;
  align-items: start;
  text-decoration: none;
  transition: 0.3s;
  font-weight: bold;
  cursor: auto;
  text-align: left;
  
  h4 {
    width: 100%;
    align-items: center;
    display: flex;
    font-weight: 200;
    opacity: 0.7;
    font-size: 14px;

    small {
      margin-left: 10px;
    }
  }

  h2 {
    margin-top: 10px;

    small {
      font-size: 12px;
    }
  }

  svg {
    margin-left: 10px;
  }
`

export const GraphWrapperPrimary = styled.div`
  display: inline-block;
  background-color: rgb(32, 32, 36, 0.3);;
  padding: 5px;
  border-radius: 20px;

  h3 {
    align-items: center;
    color: #fff;
    padding-top: 15px;
    width: 100%;
    margin-bottom: -30px;
    display: flex;
    justify-content: center;
    
    svg {
      margin-right: 10px;
    }
  }

  ul {
    margin-top: 40px;
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    overflow: auto;
    max-height: 350px;
  }

  li {
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    strong, span {
      min-width: 80px;
      background: #000;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }

    span {
      color: #ff5f5f;
      border-radius: 3px;
    }
  }
`

export const ContentGraph01 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 50px;

  @media (max-width: 1174px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ContentStatistics = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 35px;

  div {
    display: inline-block;
    background: rgb(32, 32, 36, 0.3);
    padding: 20px;
    color: #ffffff;
    border-radius: 15px 15px 3px 15px;
    justify-content: left;
    align-items: start;
    text-decoration: none;
    transition: 0.3s;
    font-weight: bold;
    cursor: auto;
    text-align: left;
    
    h4 {
      width: 100%;
      align-items: center;
      display: flex;
      font-weight: 200;
      opacity: 0.7;
      font-size: 14px;

      small {
        margin-left: 10px;
      }
    }

    h2 {
      margin-top: 10px;
    }

    svg {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 865px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 488px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  width: 100%;
  margin-top: 25px;

  a {
    display: flex;
    background: #202024;
    padding: 20px;
    color: #ffffff;
    border-radius: 15px 15px 3px 15px;
    height: 70px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    border: 5px solid #202024;
    font-weight: bold;
    font-size: 14px;
    
    svg {
      margin-right: 10px;
    }
    
    &:hover {
      border-radius: 3px 15px 15px 15px;
      transition: 0.3s;
      color: #0086ef;
      border: 5px solid #0086ef;
    }
  }
  
  @media (max-width: 865px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 488px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Footer = styled.div`
  width: 100%;
  max-width: 1200px;
  position: fixed;
  bottom: 10px;
  background: #000;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }

`

interface LineStatisticsParams {
  readonly color?: any
}

export const LineStatistics = styled.span<LineStatisticsParams>`
  margin-top: 8px;
  border-top: 2px solid ${(props: any) => props.color ? props.color : 'transparent'};
  border-radius: 2px;
  display: flex;
  width: 100%;
`

export const ShippingStatistics = styled.h4`
  font-size: 14px;

  small {
    margin-left: 0px !important;
  }
`

export const FormMessage = styled.div`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border-top: 1px solid rgb(76 76 76);
  display: inline-flex;

  button {
    padding: 5px;
    background: #0086ef;
    border-radius: 25px 25px 5px 25px;
    color: white;
    border: 1px solid;
    align-items: center;
    display: flex;
    padding-right: 14px;
    padding-left: 14px;
  }
`