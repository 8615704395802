import React, { useState } from 'react';

export const StudentContext = React.createContext<any>('');
 
export const StudentProvider: any = (props: any) => {
  const [school, setSchool] = useState('')

  return (
    <StudentContext.Provider value={{ school, setSchool }}>
      {props.children}
    </StudentContext.Provider>
  )
}
