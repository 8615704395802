/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Wrapper, Content, HeaderPage } from './styles';
import api from '../../../services/api';
import { Loading } from '../../Loading';
import { AuthContext } from '../../../contexts/auth';
import { ButtonDashboard } from '../../ButtonDashboard';
import { MdChevronLeft, MdDoneAll } from 'react-icons/md';
import { InputDashboard } from '../../InputDashboard';
import { InputGroupDashboard } from '../../InputGroupDashboard';
import history from '../../../history'

export function Shipping() {
  const { handleLogout } = React.useContext(AuthContext)

  const [data, setData] = useState({
    token: '',
    cep_from: '',
  })
  const [dataToBody, setDataToBody] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getConfigShipping() {
      setLoading(true)
      const response = await api.get('/configurations/melhor-envio').catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      if (response && response.data) {
        setData({
          token: response.data.token,
          cep_from: response.data.cep_from,
        })
      } 

      setLoading(false)
    }

    getConfigShipping(); 
  }, [])

  async function handleSave() {
    setLoading(true)

    const response = await api.put('/configurations/melhor-envio', dataToBody).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    setLoading(false)

    if (response && response.status === 200) history.push('/configurations')
  }

  return (
    <Wrapper>
      <HeaderPage>
        <div>
          <h3><MdChevronLeft size={22} onClick={() => history.push('/configurations')} />Configurações - Frete</h3>
        </div>
        <div>
          <ButtonDashboard onClick={() => handleSave()} color='#12a93f' text='Salvar' icon={<MdDoneAll size={20} />} />
        </div>
      </HeaderPage>
      <Content>
        {loading ? <Loading /> :
          <>
            <InputGroupDashboard>
              <InputDashboard placeholder='Token' defaultValue={data.token} onChange={(e: any) => setDataToBody({...dataToBody, token: e.target.value})}/>
              <InputDashboard placeholder='Cep Remetente' defaultValue={data.cep_from} onChange={(e: any) => setDataToBody({...dataToBody, cep_from: e.target.value})}/>
            </InputGroupDashboard>
          </>
        }
      </Content>
    </Wrapper>
  )
}
