import styled from 'styled-components'

interface ContentProps {
  readonly hasError: boolean;
};

export const Label = styled.span`
  font-size: 12px;
  opacity: 0.8;
`

export const Content = styled.div<ContentProps>`
  display: block;
  width: 100%;
  text-align: left;
  margin: 5px;

  div {
    display: inline-flex;
    width: 100%;
    align-items: center;

    input {
      background: #1a1919;
      color: #c1c1c1;
      padding: 10px;
      border-radius: 4px;
      display: flex;
      border: 2px solid #1a1919;
      outline: none;
      width: 100%;
      margin-right: 10px;
    }

    svg {
      opacity: 0.8;
      margin-left: -40px;
      cursor: pointer;
    }
  }
`