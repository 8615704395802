/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import { Wrapper, Content, HeaderPage, SwitchContent, 
  ContentHeader, TabAddPayment, TabBoleto, ListPayments } from './list-style';
import api from '../../services/api';
import { Loading } from '../Loading';
import { AuthContext } from '../../contexts/auth';
import { ButtonDashboard } from '../ButtonDashboard';
import { MdChevronLeft, MdDeleteOutline, MdDoneAll, MdOutlineCancel,
  MdHistory, MdClose, MdOutlineAddCircleOutline, MdCheck,
  MdFolderOff } from 'react-icons/md';
import { InputDashboard } from '../InputDashboard';
import { InputGroupDashboard } from '../InputGroupDashboard';
import history from '../../history'
import useQuery from '../../utils/queryParams'
import Switch from 'react-switch'
import { ToastContainer, toast } from 'react-toastify';
import fileDownload from 'js-file-download'
import formatSchool from '../../utils/formatSchool';
import e from 'express';

export function AlbunsDashboardEdit() {
  const { handleLogout } = React.useContext(AuthContext)
  let query = useQuery()
  
  const [newPayment, setNewPayment] = useState<any>(false)
  const id = query.get('id')
  const [data, setData] = useState<any>({})
  const [paidPayment, setPaidPayment] = useState<any>(false)
  const [dataToBody, setDataToBody] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [files, setFiles] = useState<FileList | null>(null)
  const [payments, setPayments] = useState([])
  const [addNewAlbum, setAddNewAlbum] = useState(false)
  const [dataAlbum, setDataAlbum] = useState({
    view: false,
    name: '',
  })

  useEffect(() => {
    async function getUser() {
      setLoading(true)
      const response = await api.get(`/users/admin/${id}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      if (response && response.data) {
        setData({
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          birth_date: response.data.birth_date,
          instagram: response.data.instagram,
          tiktok: response.data.tiktok,
          x: response.data.x,
          address: response.data.address,
          school: response.data.school,
          class: response.data.class,
          address_number: response.data.address_number,
          cep: response.data.cep,
          cpf: response.data.cpf,
          rg: response.data.rg,
          points: response.data.points,
          cpf_owner: response.data.cpf_owner,
          name_owner: response.data.name_owner,
          phone_owner: response.data.phone_owner,
          city: response.data.city,
          contract_link: response.data.contract_link,
          signed: response.data.signed,
          is_approved: response.data.is_approved,
          is_complete: response.data.is_complete
        })

        setDataToBody({
          ...dataToBody,
          signed: response.data.signed,
          is_approved: response.data.is_approved,
          is_complete: response.data.is_complete,
        })
      }

      setLoading(false)
    }

    async function getPayments() {
      const response: any = await api.get(`/payments/${id}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      setPayments(response.data)
    }

    if (id !== null) {
      getUser();
      getPayments();
    }
  }, [])

  async function handleSave(month: number) {
    setLoading(true)

    const fileId = await handleUploadFile()

    const response = await api.post('/payments', {
      user_id: Number(id),
      file_id: fileId,
      month,
      year: 2024,
      day: Number(dataToBody.day),
      value: dataToBody.value,
      paid: paidPayment,
    }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    const payments: any = await api.get(`/payments/${id}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    setPayments(payments.data)
    setLoading(false)

    if (response && response.status === 200) {
      setNewPayment(false)
      setPaidPayment(false)
      setFiles(null)
      setDataToBody({})
    }
  }

  async function handleDelete(e: any, paymentId: any) {
    e.preventDefault()
    setLoading(true)
    await api.delete(`/payments/${paymentId}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    const response: any = await api.get(`/payments/${id}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    setPayments(response.data)
    setLoading(false)

    if (response && response.status === 204) history.push(`/dashboard/payments/edit?id=${id}`)
  }

  async function handleUploadFile() {
    if (!files || files.length === 0) {
      return
    }

    const file = files[0]

    const response: any = await api.post('/uploads', {
      name: file.name,
      contentType: file.type,
      userId: Number(id)
    }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    await axios.put(response.data.signedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    })

    return response.data.fileId
  }

  async function handleDownload(e:any, fileId: any, filename: any) {
    e.preventDefault()

    const response = await api.get(`/download/${fileId}`)

    await axios.get(response.data, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }

  async function handleAddAlbum(e: any) {
    e.preventDefault()
    console.log('oii')
  }

  return (
    <Wrapper>
      <HeaderPage>
        <>
          <div>
            <h3><MdChevronLeft size={22} onClick={() => history.push('/dashboard/albuns')} />Voltar aos álbuns</h3>
          </div>
          <div>
            {!addNewAlbum ? <ButtonDashboard onClick={() => setAddNewAlbum(true)} color='#12a93f' text='Adicionar Álbum' icon={<MdOutlineAddCircleOutline size={20} />} /> : '' }
            {addNewAlbum ? <ButtonDashboard onClick={() => setAddNewAlbum(false)} color='#ff0c54' text='Cancelar' icon={<MdDeleteOutline size={20} />} /> : '' }
          </div>
        </>
      </HeaderPage>
      {/* <HeaderPage>
        <>
          <div>
            <InputGroupDashboard>
              <InputDashboard placeholder='Título do Álbum' onChange={(e: any) => setDataAlbum({...dataAlbum, name: e.target.value})} />
              <SwitchContent>
                <h4>Visível pelo aluno?</h4>
                <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setDataAlbum({ ...dataAlbum, view: !dataAlbum.view })} checked={dataAlbum.view} defaultChecked={false} />
              </SwitchContent>
            </InputGroupDashboard>
          </div>
        </>
      </HeaderPage> */}
      <Content>
        {loading ? <Loading /> :
          <>
            <h4>Álbuns do aluno</h4>
            <ContentHeader>
              <h2>Nome: {data.name}</h2>
              <h2>Escola: {formatSchool(data.school)}</h2>
              <h2>Turma: {data.class}</h2>
            </ContentHeader>
          </>
        }
      </Content>
      <Content>
        <h5 style={{opacity: 0.5, display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}><MdFolderOff style={{marginRight: 10}} size={20} /> Nenhum álbum cadastrado ainda.</h5>
      </Content>
    </Wrapper>
  )
}
