export default function formatSchool(value: any) {
  let schoolTranslate

  switch(value) {
    case 'mestre':
      schoolTranslate = 'Mestre'
      break;
    case 'aveline':
      schoolTranslate = 'Alfredo Aveline'
      break;
    case 'estadual-farroupilha':
      schoolTranslate = 'Estadual Farroupilha'
      break;
    case 'bomretiro':
      schoolTranslate = 'Bom Retiro'
      break;
    case 'cecilia-2024':
      schoolTranslate = 'Cecília Meireles'
      break;
    case 'ifrs-caxias-2024':
      schoolTranslate = 'IFRS Caxias'
      break;
    case 'cnec-2024':
      schoolTranslate = 'CNEC'
      break;
    case 'emilio-meyer':
      schoolTranslate = 'Emílio Meyer'
      break;
    default:
      schoolTranslate = 'Escola Não Encontrada'
      break;
  }
  
  return schoolTranslate;
}