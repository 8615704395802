import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
  margin-top: 90px;
  padding: 10px;
  margin-bottom: 30px;

  h3, p {
    text-align: left;
    display: flex;
    width: 100%;
    font-weight: 200;
  }

  p {
    font-size: 14px;
    opacity: .5;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
`

export const Content = styled.div`
  display: block;
  grid-gap: 20px;
  margin: 0 auto;
  width: 100%;
  border-top: 2px solid rgb(32, 32, 36, 1);
  padding-top: 20px;

  table {
    margin-top: 20px;
  }

  a {
    display: flex;
    background: #202024;
    padding: 20px;
    color: #ffffff;
    border-radius: 15px 15px 3px 15px;
    height: 100px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    border: 5px solid #202024;
    font-weight: bold;
    
    svg {
      margin-right: 10px;
    }
    
    &:hover {
      border-radius: 3px 15px 15px 15px;
      transition: 0.3s;
      color: #0086ef;
      border: 5px solid #0086ef;
    }
  }
`

export const Footer = styled.div`
  width: 100%;
  max-width: 1200px;
  position: fixed;
  bottom: 10px;
  background: #000;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }

`

export const Pagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;

  section {
    font-size: 14px;

    &:first-child {
      opacity: 0.7;
    }

    svg {
      width: 30px;
      height: 30px;
      background: #0086ef;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
        transition: 0.2s;
      }
    }
  }
`
