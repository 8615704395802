/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import { Wrapper, Content, HeaderPage, SwitchContent, 
  ContentHeader, TabAddPayment, TabBoleto, ListPayments } from './list-style';
import api from '../../services/api';
import { Loading } from '../Loading';
import { AuthContext } from '../../contexts/auth';
import { ButtonDashboard } from '../ButtonDashboard';
import { MdChevronLeft, MdDeleteOutline, MdDoneAll, MdOutlineCancel,
  MdHistory, MdClose, MdOutlineAddCircleOutline, MdCheck,
  MdCancel, MdFilePresent, MdCheckCircleOutline } from 'react-icons/md';
import { InputDashboard } from '../InputDashboard';
import { InputGroupDashboard } from '../InputGroupDashboard';
import history from '../../history'
import useQuery from '../../utils/queryParams'
import Switch from 'react-switch'
import { ToastContainer, toast } from 'react-toastify';
import fileDownload from 'js-file-download'
import formatSchool from '../../utils/formatSchool';
import e from 'express';

export function PaymentsDashboardEdit() {
  const { handleLogout } = React.useContext(AuthContext)
  let query = useQuery()
  
  const [newPayment, setNewPayment] = useState<any>(false)
  const id = query.get('id')
  const [data, setData] = useState<any>({})
  const [paidPayment, setPaidPayment] = useState<any>(false)
  const [dataToBody, setDataToBody] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [files, setFiles] = useState<FileList | null>(null)
  const [payments, setPayments] = useState([])

  useEffect(() => {
    async function getUser() {
      setLoading(true)
      const response = await api.get(`/users/admin/${id}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      if (response && response.data) {
        setData({
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          birth_date: response.data.birth_date,
          instagram: response.data.instagram,
          tiktok: response.data.tiktok,
          x: response.data.x,
          address: response.data.address,
          school: response.data.school,
          class: response.data.class,
          address_number: response.data.address_number,
          cep: response.data.cep,
          cpf: response.data.cpf,
          rg: response.data.rg,
          points: response.data.points,
          cpf_owner: response.data.cpf_owner,
          name_owner: response.data.name_owner,
          phone_owner: response.data.phone_owner,
          city: response.data.city,
          contract_link: response.data.contract_link,
          signed: response.data.signed,
          is_approved: response.data.is_approved,
          is_complete: response.data.is_complete
        })

        setDataToBody({
          ...dataToBody,
          signed: response.data.signed,
          is_approved: response.data.is_approved,
          is_complete: response.data.is_complete,
        })
      }

      setLoading(false)
    }

    async function getPayments() {
      const response: any = await api.get(`/payments/${id}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      setPayments(response.data)
    }

    if (id !== null) {
      getUser();
      getPayments();
    }
  }, [])

  async function handleSave(month: number) {
    setLoading(true)

    const fileId = await handleUploadFile()

    const response = await api.post('/payments', {
      user_id: Number(id),
      file_id: fileId,
      month,
      year: 2024,
      day: Number(dataToBody.day),
      value: dataToBody.value,
      paid: paidPayment,
    }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    const payments: any = await api.get(`/payments/${id}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    setPayments(payments.data)
    setLoading(false)

    if (response && response.status === 200) {
      setNewPayment(false)
      setPaidPayment(false)
      setFiles(null)
      setDataToBody({})
    }
  }

  async function handleDelete(e: any, paymentId: any) {
    e.preventDefault()
    setLoading(true)
    await api.delete(`/payments/${paymentId}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    const response: any = await api.get(`/payments/${id}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    setPayments(response.data)
    setLoading(false)

    if (response && response.status === 204) history.push(`/dashboard/payments/edit?id=${id}`)
  }

  async function handleUploadFile() {
    if (!files || files.length === 0) {
      return
    }

    const file = files[0]

    const response: any = await api.post('/uploads', {
      name: file.name,
      contentType: file.type,
      userId: Number(id)
    }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    await axios.put(response.data.signedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    })

    return response.data.fileId
  }

  async function handleDownload(e:any, fileId: any, filename: any) {
    e.preventDefault()

    const response = await api.get(`/download/${fileId}`)

    await axios.get(response.data, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }

  async function handleUpdatePayment(e:any, paymentId:any, paid:any) {
    const response: any = await api.put(`/payments/${paymentId}`, {
      user_id: id,
      paid: !paid
    }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    const payments: any = await api.get(`/payments/${id}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    setPayments(payments.data)
  }

  return (
    <Wrapper>
      <HeaderPage>
        <div>
          <h3><MdChevronLeft size={22} onClick={() => history.push('/dashboard/payments')} />Voltar aos pagamentos</h3>
        </div>
        <div>
        </div>
      </HeaderPage>
      <Content>
        {loading ? <Loading /> :
          <>
            <h4>Pagamentos do aluno</h4>
            <ContentHeader>
              <h2>Nome: {data.name}</h2>
              <h2>Escola: {formatSchool(data.school)}</h2>
              <h2>Turma: {data.class}</h2>
            </ContentHeader>
            <Tabs>
              <TabList>
                <Tab>Janeiro</Tab>
                <Tab>Fevereiro</Tab>
                <Tab>Março</Tab>
                <Tab>Abril</Tab>
                <Tab>Maio</Tab>
                <Tab>Junho</Tab>
                <Tab>Julho</Tab>
                <Tab>Agosto</Tab>
                <Tab>Setembro</Tab>
                <Tab>Outubro</Tab>
                <Tab>Novembro</Tab>
                <Tab>Dezembro</Tab>
              </TabList>

              {/* Janeiro */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={1} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(1)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 1) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}}  onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Fevereiro */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={2} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(2)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 2) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Março */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={3} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(3)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 3) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Abril */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={4} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(4)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 4) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Maio */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={5} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(5)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 5) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Junho */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={6} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(6)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 6) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Julho */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={7} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(7)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 7) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Agosto */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={8} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(8)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 8) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Setembro */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={9} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(9)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 9) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Outubro */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={10} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(10)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 10) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Novembro */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={11} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(11)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 11) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
              {/* Dezembro */}
              <TabPanel>
                <TabAddPayment>
                  {!newPayment ? <button onClick={() => setNewPayment(!newPayment)}><MdOutlineAddCircleOutline size={20} /> Adicionar pagamento</button> : ''}
                  {newPayment ? <button style={{ background: 'rgb(255 56 56)' }} onClick={() => setNewPayment(!newPayment)}><MdOutlineCancel size={20} /> Cancelar</button> : ''}
                  {newPayment ? (
                    <div>
                      <InputGroupDashboard>
                        <InputDashboard type='number' placeholder='Dia' defaultValue={1} onChange={(e: any) => setDataToBody({...dataToBody, day: e.target.value})}/>
                        <InputDashboard placeholder='Mês' disabled defaultValue={12} />
                        <InputDashboard placeholder='Ano' disabled defaultValue={2024} />
                        <InputDashboard placeholder='Valor' defaultValue={0} onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
                        <SwitchContent>
                          <h4>Pago</h4>
                          <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setPaidPayment(!paidPayment)} checked={paidPayment} defaultChecked={paidPayment} />
                        </SwitchContent>
                      </InputGroupDashboard>
                      <TabBoleto>
                        {!files && <label htmlFor="input-file"><MdFilePresent size={20} /> Anexar boleto</label>}
                        {files && files[0]?.name && <label onClick={() => setFiles(null)} style={{ background: 'rgb(255 56 56)' }} htmlFor="input-file"><MdOutlineCancel size={20} /> Excluir arquivo</label>}
                        {files && files[0]?.name && <p>{files[0]?.name} selecionado</p>}
                        <button onClick={() => handleSave(12)} style={{ marginLeft: '10px' }}><MdCheckCircleOutline size={20} /> Salvar</button>
                        <input
                          type="file"
                          id="input-file"
                          onChange={e => setFiles(e.target.files)}
                          style={{ display: "none" }}
                          />
                      </TabBoleto>
                    </div>
                  ) : ''}
                </TabAddPayment>
                <ListPayments>
                  <>
                    <li>
                      <h2>Data</h2>
                      <h2>Valor</h2>
                      <h2>Pago</h2>
                      <h2>Boleto</h2>
                      <h2></h2>
                    </li>
                    {payments && payments.map((item: any) => {
                      const urledit = '?id=' + id
                      if (item.month === 12) {
                        return (
                          <li key={item.id}>
                            <h2 onClick={() => setConfirmDelete(false)}>{item.day}/{item.month}/{item.year}</h2>
                            <h2 onClick={() => setConfirmDelete(false)}>R$ {item.value}</h2>
                            <h2 style={{cursor: 'pointer'}} onClick={() => setConfirmDelete(false)}>{(item.paid === null || !item.paid) ? <p onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Não</p> : <small onClick={(e) => handleUpdatePayment(e, item.id, item.paid)}>Sim</small>}</h2>
                            {item.file_id ? <Link to='#' onClick={(e) => handleDownload(e, item.file_id, `Boleto-${data.name}-${new Date()}.pdf`)} target='_blank' download> Download Boleto </Link> : <Link style={{ opacity: 0.5 }} to={urledit}> Sem arquivo </Link> }
                            <span onClick={(e) => handleDelete(e, item.id)}><MdClose onClick={() => setConfirmDelete(true)} size={25} /></span>
                          </li>
                        )
                      }
                    })
                    }
                  </>
                </ListPayments>
              </TabPanel>
            </Tabs>          
          </>
        }
      </Content>
    </Wrapper>
  )
}
