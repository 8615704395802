/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../services/api';
import history from '../history';
import translateApiErrors from '../utils/translateApiErrors'
import { toast } from 'react-toastify'

export const AuthContext = React.createContext<any>({name: '', token: '', authenticated: false});
 
export const AuthProvider: any = (props: any) => {
  const [auth, setAuth] = useState({
    name: '',
    email: '',
    uid: '',
    token: '',
    authenticated: false,
    admin: false,
    is_complete: false,
    error: false,
    errorMessage: '',
    school: '',
    signed: false,
    points: 0,
    contract_link: '',
  })
  const [loadingLogin, setLoadingLogin] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('diamond_token')
    const userId: any = localStorage.getItem('diamond_user_id')
    const userName = localStorage.getItem('diamond_user_name') ?? ''
    const admin = localStorage.getItem('diamond_user_admin') === 'true' ? true : false
    const email: any = localStorage.getItem('diamond_user_email')
    const is_complete: any = localStorage.getItem('diamond_user_is_complete') === 'true' ? true : false
    const school: any = localStorage.getItem('diamond_user_school')
    const signed: any = localStorage.getItem('diamond_user_signed') === 'true' ? true : false
    const points: any = localStorage.getItem('diamond_user_points')
    const contract_link: any = localStorage.getItem('diamond_user_contract_link')

    if (token) {
      setAuth({ 
        ...auth,
        authenticated: true,
        uid: JSON.parse(userId),
        token: JSON.parse(token),
        name: userName,
        admin: admin,
        email,
        is_complete,
        school,
        signed,
        points,
        contract_link,
      })
    }

    setLoadingLogin(false)
  }, [])

  async function handleLogin(body: any) {
    try {
      if (!body.email || !body.password) {
        setAuth({
          ...auth,
          error: true,
          errorMessage: translateApiErrors('BODY-01')
        })

        return
      }

      setLoadingLogin(true)
  
      const response = await api.post('/login', body);
  
      localStorage.setItem('diamond_token', JSON.stringify(response.data.token))
      localStorage.setItem('diamond_user_name', response.data.user.name)
      localStorage.setItem('diamond_user_email', response.data.user.email)
      localStorage.setItem('diamond_user_admin', JSON.stringify(response.data.user.admin))
      localStorage.setItem('diamond_user_id', JSON.stringify(response.data.user.id))
      localStorage.setItem('diamond_user_stock', JSON.stringify(response.data.user.is_stock))
      localStorage.setItem('diamond_user_is_complete', response.data.user.is_complete)
      localStorage.setItem('diamond_user_school', response.data.user.school ?? '')
      localStorage.setItem('diamond_user_signed', response.data.user.signed)
      localStorage.setItem('diamond_user_points', response.data.user.points)
      localStorage.setItem('diamond_user_contract_link', response.data.user.contract_link)

      setAuth({
        name: response.data.user.name,
        email: response.data.user.email,
        uid: response.data.user.id,
        authenticated: true,
        token: response.data.token,
        admin: response.data.user.admin,
        error: false,
        errorMessage: '',
        is_complete: response.data.user.is_complete,
        school: response.data.user.school,
        signed: response.data.user.signed,
        points: response.data.user.points,
        contract_link: response.data.user.contract_link,
      })
  
      setLoadingLogin(false)
      
      if (response.data.user.admin) history.push('/dashboard')
      if (response.data.user.is_complete && !response.data.user.admin) history.push('/dashaluno')
    } catch (err: any) {
      const code = err.response.data.code ? err.response.data.code : ''
      toast.error("Erro ao realizar login!", {
        position: toast.POSITION.TOP_RIGHT
      });
      setAuth({
        ...auth,
        error: true,
        errorMessage: translateApiErrors(code)
      })

      setLoadingLogin(false)
    }
  }

  async function handleLogout() {
    localStorage.removeItem('diamond_token');
    localStorage.removeItem('diamond_user_name');
    localStorage.removeItem('diamond_user_email');
    localStorage.removeItem('diamond_user_admin');
    localStorage.removeItem('diamond_user_id');
    localStorage.removeItem('diamond_user_stock');
    localStorage.removeItem('diamond_user_is_complete');
    localStorage.removeItem('diamond_user_school');
    localStorage.removeItem('diamond_user_signed');
    localStorage.removeItem('diamond_user_points');
    localStorage.removeItem('diamond_user_contract_link');

    setAuth({
      name: '',
      email: '',
      uid: '',
      authenticated: false,
      token: '',
      admin: false,
      error: false,
      errorMessage: '',
      is_complete: false,
      school: '',
      signed: false,
      points: 0,
      contract_link: ''
    })

    history.push('/')
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, handleLogin, handleLogout, loadingLogin, setLoadingLogin }}>
      {props.children}
    </AuthContext.Provider>
  )
}
