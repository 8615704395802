import React, { useEffect, useState } from 'react';
import { Wrapper, Content, HeaderPage, Qty } from './styles';
import { MdKeyboardArrowLeft, MdDoneAll, MdOutlineFactCheck } from 'react-icons/md';
import api from '../../../services/api'
import { Loading } from '../../Loading'
import history from '../../../history'
import { ButtonDashboard } from '../../ButtonDashboard';

export function StockEcommerce() {
  const [loading, setLoading] = useState<any>(false)
  const [data, setData] = useState([])
  const [disabledEdit, setDisabledEdit] = useState<any>(true)
  const [dataEdit, setDataEdit] = useState<any>([])

  useEffect(() => {
    getStock()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getStock() {
    try {
      setLoading(true)

      const products: any = await api.get('/products').finally(() => {
        setLoading(false)
      })

      setData(products.data)
    } catch(err) {
      setLoading(false)
    }
  }

  function handleOpenEdit() {
    setDisabledEdit(!disabledEdit)
  }

  async function handleUpdateStock() {
    setDisabledEdit(true)

    await Promise.all(dataEdit.map(async (item: any) => {
      await api.put(`/products/${item.id}`, { stock: item.value }).catch(err => console.log(err))
    }))

    await getStock()
  }

  function handleAddItemToUpdate(value: any, id: number) {
    const itens: any = []
    let isListed = false

    dataEdit.length > 0 && dataEdit.map((item: any) => {
      if (item.id === id) {
        item.value = value === '' ? 0 : value

        isListed = true;
      }

      itens.push(item)
      return item
    })

    if (dataEdit.length <= 0 || !isListed) {
      setDataEdit([...dataEdit, { value: value === '' ? 0 : value, id }])

      return
    }

    setDataEdit(itens)
  }

  return (
    <>
      <Wrapper>
        <HeaderPage>
          <h3 onClick={() => history.push('/dashboard')}><MdKeyboardArrowLeft size={30} />Estoque</h3>
          <div>
            <ButtonDashboard onClick={() => handleOpenEdit()} color='#f08d39' text='Editar' icon={<MdOutlineFactCheck size={20} />} />
            {!disabledEdit ? <ButtonDashboard onClick={() => handleUpdateStock()} color='#12a93f' text='Salvar' icon={<MdDoneAll size={20} />} /> : ''}
          </div>
        </HeaderPage>
        <Content>
          {loading ? <Loading /> : (
            <>
              <ul>
                {data.map((stock: any) => {
                  return <li>{stock.name} <Qty> <input disabled={disabledEdit} pattern="[0-9]*" inputMode='numeric' placeholder='Qtd' type='number' onChange={(e) => handleAddItemToUpdate(e.target.value, stock.id)} defaultValue={stock.stock} /></Qty> </li>
                })}
              </ul>
            </>
          )}
        </Content>
      </Wrapper>
    </>
  )
}
