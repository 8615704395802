/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Wrapper, Content, Pagination } from './styles';
import { Table } from '../Table'
import { SearchDashboard } from '../SearchDashboard';
import api from '../../services/api';
import { Loading } from '../Loading';
import { formatListAll } from './format';
import { AuthContext } from '../../contexts/auth';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md'

export function MessagesDashboard() {
  const [loading, setLoading] = useState(true)
  const { handleLogout } = React.useContext(AuthContext)

  const columns = [
    '#',
    'Nome',
    'E-mail',
    'Telefone',
    'Data',
    'MedTech Club'
  ];
  const [data, setData] = useState([{}])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    async function getLeads() {
      setLoading(true)
      const response: any = await api.get('/leads', {
        params: {
          size: 50,
          page: currentPage
        }
      }).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      const leads: any = []

      response && response.data.leads.map((lead: any) => {
        leads.push({...lead, medtech: 'Sim', subject: '', message: ''})

        return lead
      })

      
      const totalPagesRounded = Math.round(((response.data.totalRecords * 1) / 50)) < 1 ? 1 : Math.round(((response.data.totalRecords * 1) / 50))

      setTotalPages(totalPagesRounded)
      setData(formatListAll(leads))
      setLoading(false)
    }

    getLeads();
  }, [currentPage])

  return (
    <Wrapper>
      <h3>Mensagens</h3>
      <p>Lista do cadastro de pessoas no MedTech Club e Contatos</p>
      <Content>
        <SearchDashboard />
        {loading ? <Loading /> : (
          <>
            <Table columns={columns} data={data} />
            <Pagination>
              <section>Página {currentPage} de {totalPages}</section>
              <section>{currentPage > 1 ? <MdOutlineChevronLeft onClick={() => setCurrentPage(currentPage - 1)} size={30} /> : '' } {currentPage !== totalPages ? <MdOutlineChevronRight onClick={() => setCurrentPage(currentPage + 1)} size={30} /> : ''}</section>
            </Pagination>
          </>
        ) }
      </Content>
    </Wrapper>
  )
}
