import React from 'react';
import { GlobalStyle } from './styles/global';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { Header } from './components/Header';
import { Contact } from './components/Contact';
import { LinkContact } from './components/LinkContact';
import { Main } from './components/Main';
import { LoginCustomer } from './components/LoginCustomer';
import { HeaderDashboard } from './components/HeaderDashboard';
import { Dashboard } from './components/Dashboard';
import { UsersDashboard } from './components/UsersDashboard';
import { MessagesDashboard } from './components/MessagesDashboard';
import { LoadingPage } from './components/LoadingPage';
import { CouponsDashboard } from './components/CouponsDashboard';
import { CouponsDashboardEdit } from './components/CouponsDashboard/edit';
import { Configurations } from './components/Configurations';
import { Shipping } from './components/Configurations/Shipping';
import { UsersDashboardEdit } from './components/UsersDashboard/edit';
import { OrdersDashboard } from './components/OrdersDashboard';
import { OrdersDashboardEdit } from './components/OrdersDashboard/edit';
import { OrdersEcommerceEdit } from './components/Ecommerce/OrdersEcommerce/edit';
import { StockEcommerce } from './components/Ecommerce/StockEcommerce';

import { PaymentsDashboard } from './components/PaymentsDashboard';
import { PaymentsDashboardList } from './components/PaymentsDashboard/list';
import { PaymentsDashboardEdit } from './components/PaymentsDashboard/edit';

import { AlbunsDashboard } from './components/AlbunsDashboard';
import { AlbunsDashboardList } from './components/AlbunsDashboard/list';
import { AlbunsDashboardEdit } from './components/AlbunsDashboard/edit';

import { DashboardStudent } from './components/DashboardStudent';
import { HeaderDashboardStudent } from './components/HeaderDashboardStudent';
import { ContratoStudent } from './components/ContratoStudent';
import { PaymentsStudent } from './components/PaymentsStudent';
import { LojaStudent } from './components/LojaStudent';
import { CronogramaStudent } from './components/CronogramaStudent';

import {ToastContainer} from 'react-toastify'

import history from './history';
import { AuthContext } from './contexts/auth';

function CustomRoute({ isPrivate, isPrivateCustomer, ...rest } : any) {
  const { auth, loadingLogin } = React.useContext(AuthContext)

  if (loadingLogin) {
    return <LoadingPage />
  }

  if (isPrivate && !auth.authenticated) {
    return <Redirect to='/' />
  }

  if (isPrivate && !auth.admin) {
    return <Redirect to='/' />
  }
  
  if (isPrivateCustomer && !auth.authenticated) {
    return <Redirect to='/admin/login' />
  }

  return <Route { ...rest } />
}

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <CustomRoute exact path='/'>
            <LoginCustomer />
          </CustomRoute>
          <CustomRoute exact path='/contact'>
            <Header />
            <Contact />
          </CustomRoute>
          <CustomRoute exact path='/link-contact'>
            <Header />
            <LinkContact />
          </CustomRoute>
          <CustomRoute exact path='/admin/login'>
            <LoginCustomer />
          </CustomRoute>
          {/* <CustomRoute isPrivateCustomer exact path='/shop/products'>
            <Header isLogged />
            <ProductsEcommerce />
          </CustomRoute>
          <CustomRoute isPrivateCustomer exact path='/shop/my-orders'>
            <Header isLogged />
            <OrdersEcommerce />
          </CustomRoute>
          <CustomRoute isPrivateCustomer exact path='/shop/my-comissions'>
            <Header isLogged />
            <ComissionsEcommerce />
          </CustomRoute>
          <CustomRoute isPrivateCustomer exact path='/shop/my-dash'>
            <Header isLogged />
            <UserProfileEcommerce />
          </CustomRoute>
          <CustomRoute isPrivateCustomer exact path='/shop/my-profile'>
            <Header isLogged />
            <ProfileEditEcommerce />
          </CustomRoute> */}
          <CustomRoute isPrivateCustomer path='/dashaluno'>
            <HeaderDashboardStudent />
            <DashboardStudent />
          </CustomRoute>
          <CustomRoute isPrivateCustomer path='/contrato'>
            <HeaderDashboardStudent />
            <ContratoStudent />
          </CustomRoute>
          <CustomRoute isPrivateCustomer path='/pagamentos'>
            <HeaderDashboardStudent />
            <PaymentsStudent />
          </CustomRoute>
          <CustomRoute isPrivateCustomer path='/loja'>
            <HeaderDashboardStudent />
            <LojaStudent />
          </CustomRoute>
          <CustomRoute isPrivateCustomer path='/cronograma'>
            <HeaderDashboardStudent />
            <CronogramaStudent />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard'>
            <HeaderDashboard />
            <Dashboard />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/stocks'>
            <HeaderDashboard />
            <StockEcommerce />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/orders'>
            <HeaderDashboard />
            <OrdersDashboard />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/orders/edit'>
            <HeaderDashboard />
            <OrdersDashboardEdit />
          </CustomRoute>
          <CustomRoute isPrivateCustomer exact path='/seller/orders/edit'>
            <Header isLogged />
            <OrdersEcommerceEdit />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/users'>
            <HeaderDashboard />
            <UsersDashboard />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/users/edit'>
            <HeaderDashboard />
            <UsersDashboardEdit />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/albuns'>
            <HeaderDashboard />
            <AlbunsDashboard />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/albuns/list'>
            <HeaderDashboard />
            <AlbunsDashboardList />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/albuns/edit'>
            <HeaderDashboard />
            <AlbunsDashboardEdit />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/payments'>
            <HeaderDashboard />
            <PaymentsDashboard />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/payments/list'>
            <HeaderDashboard />
            <PaymentsDashboardList />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/payments/edit'>
            <HeaderDashboard />
            <PaymentsDashboardEdit />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/messages'>
            <HeaderDashboard />
            <MessagesDashboard />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/coupons'>
            <HeaderDashboard />
            <CouponsDashboard />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/dashboard/coupons/edit'>
            <HeaderDashboard />
            <CouponsDashboardEdit />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/configurations'>
            <HeaderDashboard />
            <Configurations />
          </CustomRoute>
          <CustomRoute isPrivate exact path='/configurations/shipping'>
            <HeaderDashboard />
            <Shipping />
          </CustomRoute>
        </Switch>
        <GlobalStyle />
      </Router>
    </>
  );
}

export default App;
