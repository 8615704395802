import styled from 'styled-components'

export const Wrapper = styled.table`
  width: 100%;
  border-top: 3px solid rgb(32, 32, 36, .6);
  padding-top: 10px;
  padding-bottom: 20px;
  background: rgb(32, 32, 36, .6);
  padding: 10px;
  border-radius: 10px;
  display: block;
  overflow:auto;
  border-spacing: 0;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f0f2f5;
    border: 3px solid #f0f2f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    background: transparent;
  }

  tbody {
    display: table;
    width: 100%;

    tr {
      &:hover {
        background: #19191c;
      }
    }

    &:first-child {
      text-align: left;
      display: table;
      width: 100%;

      th {
        color: #797979;
        font-weight: 200;
        font-size: 12px;
        padding-bottom: 20px;
        padding-left: 10px;
      }
    }
  }

  td {
    transition: .2s;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    font-weight: 200;
    font-size: 14px;
    align-items: center;
    padding-right: 20px;

    img {
      max-width: 80px;
      border-radius: 3px;
    }

    svg {
      cursor: pointer;
      color: #1d2a36;
    }

    &:hover {
      transition: .2s;
      opacity: .7;
    }
  }
`