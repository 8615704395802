import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Wrapper, Content, ContentStatistics, LineStatistics,
  ShippingStatistics, FormMessage,
} from './styles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MdOutlineDiamond } from 'react-icons/md'
import { FiSend } from 'react-icons/fi'
import { FaRegUserCircle } from 'react-icons/fa'
import { format } from 'date-fns';
import api from '../../services/api'
import { Input } from '../Input'
import CountUp from 'react-countup';
import carroussel1 from '../../assets/diamond01.png'
import carroussel2 from '../../assets/diamond02.png'
import Calendar from 'react-calendar';
import { AuthContext } from '../../contexts/auth';

import 'react-calendar/dist/Calendar.css';

export function DashboardStudent() {
  const { handleLogout, auth } = React.useContext(AuthContext)
  const [daysToEnd, setDaysToEnd] = useState(0)
  const [messages, setMessages] = useState<any>([])
  const [calendarValue, setCalendarValue] = useState<any>(new Date());

  useEffect(() => {
    if (auth.school === 'aveline') {
      let date = (new Date("Dec 14 2024")).getTime();
      let today = (new Date()).getTime();
      let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
  
      setDaysToEnd(Math.abs(Math.floor((today - date) / msDay)));
    } else if (auth.school === 'mestre') {
      let date = (new Date("Dec 20 2024")).getTime();
      let today = (new Date()).getTime();
      let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
  
      setDaysToEnd(Math.abs(Math.floor((today - date) / msDay)));  
    } else if (auth.school === 'bomretiro') {
      let date = (new Date("Dec 19 2024")).getTime();
      let today = (new Date()).getTime();
      let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
  
      setDaysToEnd(Math.abs(Math.floor((today - date) / msDay)));  
    } else if (auth.school === 'estadual-farroupilha') {
      let date = (new Date("Dec 21 2024")).getTime();
      let today = (new Date()).getTime();
      let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
  
      setDaysToEnd(Math.abs(Math.floor((today - date) / msDay)));  
    }

    async function getMessages() {
      const response: any = await api.get(`/messageuser?school=${auth.school}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      setMessages(response.data)
    }

    getMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <ContentStatistics>
        <div>
          <h4>Contagem Regressiva</h4>
          <LineStatistics color='#0086ef' />
          {/* <h2><CountUp end={230} formattingFn={(value) => 'R$ ' + numberToReal(value)} duration={1}/></h2> */}
          <h2><CountUp end={daysToEnd} formattingFn={(value) => value + ' dias'} duration={1}/></h2>
          <ShippingStatistics><small>Para o grande dia!</small></ShippingStatistics>
        </div>
        <div>
          <h4>Clube Diamond <MdOutlineDiamond size={17} /></h4>
          <LineStatistics color='#e6843a' />
          {/* <h2><CountUp end={data.totalWeek} formattingFn={(value) => 'R$ ' + numberToReal(value)} duration={1}/></h2> */}
          <h2><CountUp end={auth.points} formattingFn={(value) => value + ' pontos'} duration={1}/></h2>
          <ShippingStatistics><small>Complete as tarefas e ganhe pontos!</small></ShippingStatistics>
        </div>
        <div>
          <h4>Status do Contrato</h4>
          {/* <LineStatistics color='#e63a3a' /> */}
          <LineStatistics color='#b6ff41' />
          {/* <h2><CountUp end={data.totalMonth} formattingFn={(value) => 'R$ ' + numberToReal(value)} duration={1}/></h2> */}
          {/* <h2><CountUp end={1} formattingFn={(value) => value + ' boleto em atraso'} duration={1}/></h2> */}
          <h2>Em dia</h2>
          <ShippingStatistics><small>Fique em dia e ganhe pontos para o Clube!</small></ShippingStatistics>
        </div>
      </ContentStatistics>
      <Carousel 
        showArrows={false} 
        showStatus={false} 
        showThumbs={false} 
        stopOnHover={false}
        interval={1000}
        infiniteLoop={true}
      >
          <div>
              <img src={carroussel1} alt='Banner Diamond' />
          </div>
          <div>
              <img src={carroussel2} alt='Banner Diamond Promo' />
          </div>
      </Carousel>
      <h3>Cronograma</h3>
      <Calendar onChange={setCalendarValue} value={calendarValue} />
      <h3>Mural de Recados</h3>
      <Tabs>
        <TabList>
          <Tab>Geral</Tab>
          <Tab>Escola</Tab>
          <Tab>Meu mural</Tab>
        </TabList>

        <TabPanel>
          {messages.map((message: any) => {
            if (message.all) {
              return (
                <h4>
                  <span><MdOutlineDiamond size={17} /></span>
                  <p>
                    <small>Equipe Diamond</small>
                    <br />
                    {message.message}
                  </p>
                </h4>
              )
            }
          })}
        </TabPanel>
        <TabPanel>
          {messages.map((message: any) => {
            if (!message.all && message.school === auth.school && !message.user_id) {
              return (
                <h4>
                  <span><MdOutlineDiamond size={17} /></span>
                  <p>
                    <small>Equipe Diamond</small>
                    <br />
                    {message.message}
                  </p>
                </h4>
              )
            }
          })}
        </TabPanel>
        <TabPanel>
          {messages.map((message: any) => {
            if  (!message.from_student && !message.all && message.user_id) {
              return (
                  <h4>
                    <span><MdOutlineDiamond size={30} /></span>
                    <p>
                      <small>Equipe Diamond</small>
                      <br />
                      {message.message}
                    </p>
                  </h4>
              )
            } else if (message.from_student && !message.all) {
              return (
                <h5>
                  <p>
                    <small>Você</small>
                    <br />
                    {message.message}
                  </p>
                  <span><FaRegUserCircle size={30} /></span>
                </h5>
              )
            }
          })}
          <FormMessage>
            <Input type='text' placeholder='Escreva sua mensagem aqui' />
            <button type='button'><FiSend size={30} /></button>
          </FormMessage>
        </TabPanel>
      </Tabs>
    </Wrapper>
  )
}
