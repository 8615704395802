/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Wrapper, Content } from './list-style';
import { Link } from 'react-router-dom';
import { Table } from '../Table'
import { SearchDashboard } from '../SearchDashboard';
import { MdArrowBack } from 'react-icons/md'
import api from '../../services/api';
import { Loading } from '../Loading';
import { formatListAll } from './format';
import { AuthContext } from '../../contexts/auth';
import { StudentContext } from '../../contexts/student';

export function AlbunsDashboardList() {
  const [loading, setLoading] = useState(true)
  const { handleLogout } = React.useContext(AuthContext)
  const { school, setSchool } = React.useContext(StudentContext)

  const columns = [
    '#',
    "Nome",
		"Escola",
		"Turma",
		"Data de Criação",
  ];
  const [data, setData] = useState([{}])

  useEffect(() => {
    async function getUsers() {
      const response = await api.get(`/users?school=${school}`).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      const users: any = []

      response && response.data.map((user: any) => {
        users.push({...user})

        return user
      })

      setData(formatListAll(users))
      setLoading(false)
    }

    getUsers();
  }, [])

  return (
    <Wrapper>
      <h3>Álbuns</h3>
      <p>Lista do cadastro de álbuns</p>
      <Link to='/dashboard/albuns' onClick={() => setSchool('')}><MdArrowBack size={20} /> Voltar</Link>
      <Content>
        <SearchDashboard />
        {loading ? <Loading /> : <Table hasEdit={true} columns={columns} data={data} urlToEdit='/dashboard/albuns/edit' /> }
      </Content>
    </Wrapper>
  )
}
