import React, { useState } from 'react';
import { Wrapper, FormContent, FormGroup, Footer, ButtonElement, SellerContent } from './styles'
import { FaWhatsapp } from 'react-icons/fa'
import logo from '../../assets/formadiamond-branco.png';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Input } from '../Input'
import { LanguagesContext } from '../../contexts/languages';
import api from '../../services/api';

import pt from '../../languages/pt'
import en from '../../languages/en'
import es from '../../languages/es'

export function LinkContact() {
  const { language } = React.useContext(LanguagesContext)
  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  })
  const [loading, setLoading] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [contactSuccess, setContactSuccess] = useState(false)
  let currentLang = language

  switch(language) {
    case 'pt':
      currentLang = pt
      break;
    case 'es':
      currentLang = es
      break;
    case 'en':
      currentLang = en
      break;
  }

  async function sendContact() {
    if (contact.name !== '' && contact.email !== '' && contact.phone !== '' && contact.message !== '') {
      setContactError(false)
      setLoading(true)

      const response: any = await api.post('/contact', contact).catch((err: any) => {
        setContactError(true)
        setLoading(false)
      });
      
      if (response.data.id) {
        setContactSuccess(true)
        setContact({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        })
        setTimeout(() => {
          setContactSuccess(false)
        }, 4000)
      }

      setLoading(false)
    } else {
      setContactError(true)
    }
  }

  return (
    <Wrapper>
      <h2>Contatos Autorizados</h2>
      <h5><MdKeyboardArrowDown size={35} /></h5>
      <SellerContent>
        <div>
          <a target='_blank' href='https://wa.me/32460209677' rel="noopener noreferrer"><FaWhatsapp size={24} /> BOSS MEDTECH</a>
        </div>
        <div>
          <a target='_blank' href='https://wa.me/353833339172' rel="noopener noreferrer"><FaWhatsapp size={24} /> HUGO</a>
        </div>
        <div>
          <a target='_blank' href='https://wa.me/5511983887968' rel="noopener noreferrer"><FaWhatsapp size={24} /> CARLOS</a>
        </div>
        <div>
          <a target='_blank' href='https://wa.me/5511917086459' rel="noopener noreferrer"><FaWhatsapp size={24} /> LETÍCIA</a>
        </div>
        <div>
          <a target='_blank' href='https://wa.me/554789136962' rel="noopener noreferrer"><FaWhatsapp size={24} /> ROGER</a>
        </div>
        <div>
          <a target='_blank' href='https://wa.me/555189204315' rel="noopener noreferrer"><FaWhatsapp size={24} /> FÁBIO</a>
        </div>
        <div>
          <a target='_blank' href='https://wa.me/554791221635' rel="noopener noreferrer"><FaWhatsapp size={24} /> LÉO</a>
        </div>
      </SellerContent>
    </Wrapper>
  )
}
