/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Wrapper, Content, HeaderPage } from './styles';
import api from '../../services/api';
import { Loading } from '../Loading';
import { AuthContext } from '../../contexts/auth';
import { ButtonDashboard } from '../ButtonDashboard';
import { MdChevronLeft, MdDoneAll } from 'react-icons/md';
import { InputDashboard } from '../InputDashboard';
import { InputGroupDashboard } from '../InputGroupDashboard';
import history from '../../history'

export function CouponsDashboardEdit() {
  const { handleLogout } = React.useContext(AuthContext)

  const [dataToBody, setDataToBody] = useState({})
  const [loading, setLoading] = useState(false)

  async function handleSave() {
    setLoading(true)

    const response = await api.post('/coupons', dataToBody).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    setLoading(false)

    if (response && response.status === 200) history.push('/dashboard/coupons')
  }

  return (
    <Wrapper>
      <HeaderPage>
        <div>
          <h3><MdChevronLeft size={22} onClick={() => history.push('/dashboard/coupons')} />Cupons</h3>
        </div>
        <div>
          <ButtonDashboard onClick={() => handleSave()} color='#12a93f' text='Salvar' icon={<MdDoneAll size={20} />} />
        </div>
      </HeaderPage>
      <Content>
        {loading ? <Loading /> :
          <>
            <InputGroupDashboard>
              <InputDashboard style={{ 'text-transform': 'uppercase' }} placeholder='Nome' onChange={(e: any) => setDataToBody({...dataToBody, name: e.target.value.toUpperCase()})}/>
              <InputDashboard placeholder='Pessoa' onChange={(e: any) => setDataToBody({...dataToBody, person: e.target.value})}/>
            </InputGroupDashboard>
            <InputGroupDashboard>
              <InputDashboard type='number' placeholder='Valor' onChange={(e: any) => setDataToBody({...dataToBody, value: e.target.value})}/>
              <InputDashboard type='number' placeholder='Qtd Máx' onChange={(e: any) => setDataToBody({...dataToBody, qtd_max: e.target.value})}/>
            </InputGroupDashboard>
          </>
        }
      </Content>
    </Wrapper>
  )
}
