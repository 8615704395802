import React from 'react';
import { Link } from 'react-router-dom';
import { Wrapper, Content } from './styles';
import { MdOutlineLocalShipping } from 'react-icons/md'

export function Configurations() {

  return (
    <Wrapper>
      <h3>Configurações</h3>
      <p>Configurações gerais do sistema</p>
      <Content>
        <Link to='/configurations/shipping'><MdOutlineLocalShipping size={30} /> FRETE</Link>
      </Content>
    </Wrapper>
  )
}
