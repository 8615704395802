import { Wrapper } from './styles'
import history from '../../history';

export const Table = ({ hasEdit = false, columns, data, urlToEdit } : { hasEdit?: any, columns: any, data: any, urlToEdit?: any }) => {
  return (
    <Wrapper>
      <tbody>
        <tr>
          {columns.map((column: any) => {
            return <th key={column}>{ column }</th>
          })}
        </tr>
        {data.map((item: any) => {
          const allKeys = Object.keys(item);

          if (hasEdit) {
            return (
              <tr onClick={() => history.push(`${urlToEdit}?id=${item['id']}`)} key={item[allKeys[0]]}>
                {allKeys.map(key => {
                  return <td>{ item[key] }</td>
                })}
              </tr>
            )
          }
          
          return (
            <tr key={item[allKeys[0]]}>
              {allKeys.map(key => {
                return <td>{ item[key] }</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </Wrapper>
  )
};