import React from 'react';
import { Wrapper} from './styles'
import logo from '../../assets/formadiamond-branco.png';
import { Loading } from '../Loading';

export function LoadingPage() {
  
  return (
    <>
      <Wrapper>
        <img src={logo} alt='Logo' />
        <Loading />
      </Wrapper>
    </>
  )
}
