import React, { useState, useEffect } from 'react';
import { Wrapper, FormContent, FormGroup, Footer } from './styles';
import { Input } from '../Input'
import { LanguagesContext } from '../../contexts/languages';
import { Link } from 'react-router-dom';
import { BsShieldCheck } from 'react-icons/bs'
import logo from '../../assets/formadiamond-branco.png'
import { Select } from '../Select'

import pt from '../../languages/pt'
import en from '../../languages/en'
import es from '../../languages/es'
import api from '../../services/api';
import { AuthContext } from '../../contexts/auth';

export function LoginCustomer() {
  const [newCustomer, setNewCustomer] = useState(false)
  const { language } = React.useContext(LanguagesContext)
  const { handleLogin, loadingLogin, auth, setAuth } = React.useContext(AuthContext)
  const [formData, setFormData] = useState({
    name: '',
    cpf: '',
    address01: '',
    address02: '',
    address_number: '',
    district: '',
    city: '',
    cep: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    instagram: '',
    x: '',
    tiktok: '',
    rg: '',
    name_owner: '',
    cpf_owner: '',
    phone_owner: '',
    birth_date: '',
  })
  const [error, setError] = useState({
    message: '',
    hasError: false
  })
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState({
    message: '',
    hasSuccess: false
  })
  const [dataLogin, setDataLogin] = useState({
    email: '',
    password: '',
  })

  let currentLang = language

  switch(language) {
    case 'pt':
      currentLang = pt
      break;
    case 'es':
      currentLang = es
      break;
    case 'en':
      currentLang = en
      break;
  }

  useEffect(() => {
    onAuth()

    function onAuth() {
      if (auth.authenticated) {
        setNewCustomer(true)
        
        setFormData({
          ...formData, 
          name: auth.name,
          email: auth.email
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  async function handleSubmitNewCustomer () {
    setLoading(true)
    setError({
      message: '',
      hasError: false
    })
    setAuth({
      ...auth,
      error: false,
      errorMessage: ''
    })

    if (formData.name === '' || formData.cpf === ''
      || formData.address01 === '' || formData.address_number === ''
        || formData.district === '' || formData.city === ''
          || formData.cep === '' || formData.email === '' || formData.phone === '' 
            || formData.password === '' || formData.confirm_password === ''
              || formData.rg === '' || formData.birth_date === '') {
                setError({
                  message: 'Verifique os campos obrigatórios.',
                  hasError: true
                })

                setLoading(false)
                return
              }

    const response: any = await api.put(`/users/${auth.uid}`, {
      name: formData.name,
      cpf: formData.cpf,
      rg: formData.rg,
      phone: formData.phone,
      password: formData.password,
      confirm_password: formData.confirm_password,
      birth_date: formData.birth_date,
      cpf_owner: formData.cpf_owner,
      name_owner: formData.name_owner,
      phone_owner: formData.phone_owner,
      cep: formData.cep,
      address: formData.address01 + ', ' + formData.address_number + ', ' + formData.district + ', ' + formData.city
    }).catch(err => {
      setError({
        message: err.response.data.error,
        hasError: true
      })
      
      setLoading(false)
    })

    if(response && response.status === 200) {
      setFormData({
        name: '',
        cpf: '',
        address01: '',
        address02: '',
        address_number: '',
        district: '',
        city: '',
        cep: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        instagram: '',
        x: '',
        tiktok: '',
        rg: '',
        name_owner: '',
        cpf_owner: '',
        phone_owner: '',
        birth_date: '',
      })
      setLoading(false)
      setNewCustomer(false)
      setSuccess({
        message: 'Sucesso! Cadastro criado.',
        hasSuccess: true
      })

      setTimeout(() => {
        setSuccess({
          hasSuccess: false,
          message: ''
        })
      }, 4000)
    }
  }

  return (
    <Wrapper>
      <Link to='/'><img src={logo} alt='FormaDiamond' /></Link>
      {newCustomer && (auth.is_complete === false || auth.is_complete === 'false') ?
        <FormContent onSubmit={() => handleSubmitNewCustomer()}>
          <h2>Atualização de Cadastro</h2>
          <p>Efetue seu cadastro para acessar nosso aplicativo.<br />Os campos marcados com * são obrigatórios.</p>
          {error.hasError ? <span>{error.message}</span> : ''}
          {loading ? <h2>Cadastrando...</h2> : (
            <><FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, name: e.target.value})} name="name" type="text" placeholder='Nome Completo*' required value={formData.name} />
              <Input maskChar={null} onChange={(e: any) => setFormData({...formData, cep: e.target.value})} mask="99999-999" name="cep" type="text" placeholder='CEP*' required value={formData.cep} />
            </FormGroup>
            <FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, address01: e.target.value})} name="address01" type="text" placeholder='Rua*' required value={formData.address01} />
              <Input onChange={(e: any) => setFormData({...formData, address02: e.target.value})} name="address02" type="text" placeholder='Complemento' value={formData.address02}/>
            </FormGroup>
            <FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, address_number: e.target.value})} name="number" type="number" placeholder='Número*' required value={formData.address_number} />
              <Input onChange={(e: any) => setFormData({...formData, district: e.target.value})} name="district" type="text" placeholder='Bairro*' required value={formData.district} />
            </FormGroup>
            <FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, city: e.target.value})} name="city" type="text" placeholder='Cidade*' required value={formData.city} />
            </FormGroup>
            <FormGroup>
              <Input maskChar={null} onChange={(e: any) => setFormData({...formData, cpf: e.target.value})} mask="999.999.999-99" name="cpf" type="text" placeholder='CPF*' required value={formData.cpf} />
              <Input onChange={(e: any) => setFormData({...formData, rg: e.target.value})} name="rg" type="text" placeholder='RG*' required value={formData.rg} />
            </FormGroup>
            <FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, email: e.target.value})} name="email" type="email" placeholder='E-mail*' required value={formData.email} />
              <Input maskChar={null} onChange={(e: any) => setFormData({...formData, phone: e.target.value})} mask="(99) 99999-9999" name="phone" type="text" placeholder='Telefone*' required value={formData.phone} />
            </FormGroup>
            <FormGroup>
              <Input maskChar={null} onChange={(e: any) => setFormData({...formData, birth_date: e.target.value})} mask="99/99/9999" name="birth_date" type="text" placeholder='Data de Aniversário*' required value={formData.birth_date} />
            </FormGroup>
            <FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, password: e.target.value})} name="password" type="password" placeholder='Senha*' required value={formData.password} />
              <Input onChange={(e: any) => setFormData({...formData, confirm_password: e.target.value})} name="confirmPass" type="password" placeholder='Repita a Senha*' required value={formData.confirm_password} />
            </FormGroup>
            <FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, instagram: e.target.value})} name="instagram" type="text" placeholder='Instagram' required value={formData.instagram} />
              <Input onChange={(e: any) => setFormData({...formData, x: e.target.value})} name="x" type="text" placeholder='X' required value={formData.x} />
              <Input onChange={(e: any) => setFormData({...formData, tiktok: e.target.value})} name="tiktok" type="text" placeholder='TikTok' required value={formData.tiktok} />
            </FormGroup>
            <h2>Dados do Responsável</h2>
            <p>Se você já tiver 18 anos, deixe esses campos em branco.</p>
            <FormGroup>
              <Input onChange={(e: any) => setFormData({...formData, name_owner: e.target.value})} name="name_owner" type="text" placeholder='Nome do Responsável' required value={formData.name_owner} />
              <Input maskChar={null} onChange={(e: any) => setFormData({...formData, cpf_owner: e.target.value})} mask="999.999.999-99" name="cpf_owner" type="text" placeholder='CPF do Responsável*' required value={formData.cpf_owner} />
            </FormGroup>
            <FormGroup>
            <Input maskChar={null} onChange={(e: any) => setFormData({...formData, phone_owner: e.target.value})} mask="(99) 99999-9999" name="phone_owner" type="text" placeholder='Telefone do Responsável*' required value={formData.phone_owner} />
            </FormGroup>
            </>
          )}
          {!loading ? (
            <>
              <button onClick={() => handleSubmitNewCustomer()} type='button'>Enviar Cadastro</button>
            </>
          ) : '' }
        </FormContent> : 
        <FormContent>
            <>
              <h2>Fazer Login</h2>
              <p>Para acessar o seu cadastro insira seus dados</p>
              {auth.error ? <span>{auth.errorMessage}</span> : ''}
              <Input onChange={(e: any) => setDataLogin({...dataLogin, email: e.target.value})} name="email" type="email" placeholder='E-mail' required />
              <Input onChange={(e: any) => setDataLogin({...dataLogin, password: e.target.value})} name="password" type="password" placeholder='*******' required />
              <button onClick={() => handleLogin(dataLogin)} type='button'>{loadingLogin ? 'Loading...' : currentLang.formContact.login}</button>
            </>
        </FormContent> 
      }
      <Footer>
        <p>Precisa de suporte? Envie e-mail para: diamondbento@gmail.com ou WhatsApp: (54) 99649-2397<br />Todos os direitos reservados a Forma Diamond.</p>
      </Footer>
    </Wrapper>
  )
}
