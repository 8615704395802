import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { LanguagesProvider } from './contexts/languages';
import { ActiveMenuProvider } from './contexts/activeMenu';
import { ItemsCartProvider } from './contexts/itemsCart';
import { AuthProvider } from './contexts/auth';
import { ComissionsProvider } from './contexts/comissions';
import { StudentProvider } from './contexts/student';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <LanguagesProvider>
        <ActiveMenuProvider>
          <ItemsCartProvider>
            <ComissionsProvider>
              <StudentProvider>
                <App />
              </StudentProvider>
            </ComissionsProvider>
          </ItemsCartProvider>
        </ActiveMenuProvider>
      </LanguagesProvider>
    </AuthProvider>
  </React.StrictMode>
);
