export default function statusOrder(statusOriginal: any) {
  switch(statusOriginal){
    case 'payd':
      return {name: 'Pago', color: '#40c535'};
    case 'conditional':
      return {name: 'Condicional', color: '#37dbff'};
    case 'pending':
      return {name: 'Pendente', color: '#e2c81e'};
    case 'separation':
      return {name: 'Em Separação', color: '#215237'};
    case 'closed':
      return {name: 'Concluído', color: '#c8c8c8'};
    default:
      return {name: 'Pendente', color: '#e2c81e'};
  }
}