export default function transformNameProducts(code: any) {
  switch(code) {
    case 1:
      return '05ml injetável bocal 20mm altura 36mm diametro do corpo 20,5mm'
    case 2:
      return 'Âmbar 14ml'
    case 3:
      return 'Dosador Plastico 250ml'
    case 4:
      return 'Frasco Pet Branco'
    case 5:
      return 'Proveta 100ml'
    case 6:
      return 'Pote de Plastico 250ml'
    case 7:
      return 'Conta Gotas 15ml'
    case 8:
      return 'Âmbar 10ml'
    case 9:
      return 'Frasco de vidro transparente 60ml'
    case 10:
      return 'Frasco de vidro transparente 100ml'
    case 11:
      return 'Frasco de vidro transparente 250ml'
    case 12:
      return 'Frasco de vidro transparente 500ml'
    case 13:
      return '02ml injetável bocal 13mm altura 37mm diametro do corpo 13,7mm'
    case 14:
      return '05ml injetável bocal 13mm altura 40mm diametro do corpo 16,5mm'
    case 15:
      return 'Âmbar 15ml'
    case 16:
      return 'Âmbar 20ml'
    case 17:
      return 'Âmbar 30ml'
    default:
      return 'Âmbar 50ml'
  }
};
