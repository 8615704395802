import React, { useState } from 'react'
import { 
  MdFace, MdLogout, MdOutlinePaid,
  MdCalendarToday, MdSegment, MdFilePresent
} from 'react-icons/md'
import { IoCubeOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom';
import {
  Content, Wrapper, IconContent, MenuContent, 
  HamburguerMenu, HamburguerMenuContent, ContentProfile
} from './styles'
import logo from '../../assets/formadiamond-branco.png'
import { AuthContext } from '../../contexts/auth';
import profile from '../../assets/profile.png'

export function HeaderDashboardStudent() {
  const { handleLogout, auth } = React.useContext(AuthContext)
  const [hamburguerMenuOpen, setHamburguerMenuOpen] = useState(false)

  return (
    <Wrapper>
      <Content>
        <ContentProfile>
          <Link to='/dashaluno'>
            <span>
              <img src={profile} alt='FormaDiamond Perfil' />
            </span>
            <h3>
              <small>Bem Vindo(a)</small><br />
              {auth.name}
            </h3>
          </Link>
        </ContentProfile>
        <MenuContent>
          <li><Link to='#' onClick={() => setHamburguerMenuOpen(!hamburguerMenuOpen)}><MdSegment size={20} /> Menu</Link></li>
        </MenuContent>
        <HamburguerMenu onClick={() => setHamburguerMenuOpen(!hamburguerMenuOpen)}><MdSegment size={40} /></HamburguerMenu>
      </Content>
      {hamburguerMenuOpen ? (
          <HamburguerMenuContent>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/contrato'><MdFilePresent size={20} /> Contrato</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/pagamentos'><MdOutlinePaid size={20} /> Pagamentos</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/loja'><IoCubeOutline size={20} /> Loja Diamond</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/cronograma'><MdCalendarToday size={20} /> Cronograma</Link></li>
            {/* <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/meu-perfil'><MdFace size={20} /> Meu Perfil</Link></li> */}
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='#' onClick={() => handleLogout()}><MdLogout size={20}/> Sair</Link></li>
          </HamburguerMenuContent>
        ) : ''}
    </Wrapper>
  )
}
