import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Wrapper, Content, ContentStatistics, LineStatistics, 
  ShippingStatistics } from './styles';
import { MdFace, MdOutlineMarkAsUnread, MdOutlinePaid, MdCalendarToday, MdOutlineSportsVolleyball } from 'react-icons/md'
import { HiOutlineTicket } from 'react-icons/hi'
import { VscGraphLine } from 'react-icons/vsc'
import { format } from 'date-fns';
import api from '../../services/api'
import CountUp from 'react-countup';
import { AuthContext } from '../../contexts/auth';
import { StudentContext } from '../../contexts/student';

export function PaymentsDashboard() {
  const { school, setSchool } = React.useContext(StudentContext)

  useEffect(() => {
    // async function getUsers() {
    //   const response = await api.get('/users').catch(err => {
    //     if (err.response.status === 401) handleLogout()
    //   })

    //   const users: any = []
    //   const signedUsers: any = []
    //   const notSignedUsers: any = []

    //   response && response.data.map((user: any) => {
    //     users.push({...user})

    //     if (user.signed) signedUsers.push({...user})
    //     if (!user.signed) notSignedUsers.push({...user})

    //     return user
    //   })

    //   setData({
    //     ...data,
    //     totalStudents: users.length,
    //     totalSigned: signedUsers.length,
    //     totalNotSigned: notSignedUsers.length,
    //   })
    // }

    // getUsers();
  }, [])

  return (
    <Wrapper>
      <p>Escolha a escola para gerenciar os pagamentos</p>
      <Content>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('aveline')}><MdOutlinePaid size={20} /> AVELINE</Link>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('bomretiro')}><MdOutlinePaid size={20} /> BOM RETIRO</Link>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('cecilia-2024')}><MdOutlinePaid size={20} /> CECÍLIA</Link>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('cnec-2024')}><MdOutlinePaid size={20} /> CNEC</Link>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('emilio-meyer')}><MdOutlinePaid size={20} /> EMILIO MEYER</Link>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('estadual-farroupilha')}><MdOutlinePaid size={20} /> ESTADUAL FARROUPILHA</Link>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('ifrs-caxias-2024')}><MdOutlinePaid size={20} /> IFRS CAXIAS</Link>
        <Link to='/dashboard/payments/list' onClick={() => setSchool('mestre')}><MdOutlinePaid size={20} /> MESTRE</Link>
      </Content>
    </Wrapper>
  )
}
