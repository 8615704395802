import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Wrapper, Content, ContentStatistics, LineStatistics,
  ShippingStatistics 
} from './styles';
import { MdCheckCircleOutline } from 'react-icons/md'
import { AuthContext } from '../../contexts/auth';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'react-tabs/style/react-tabs.css';

export function ContratoStudent() {
  const { handleLogout, auth } = React.useContext(AuthContext)

  return (
    <Wrapper>
      <ContentStatistics>
        <div>
          <h4>Status do Contrato</h4>
          {auth.signed ? (
              <>
                <LineStatistics color='#b6ff41' />
                <h2>Assinado</h2>
              </>) : (
              <>
                <LineStatistics color='#e63a3a' />
                <h2>Pendente</h2>
              </>
            )}
          <ShippingStatistics><small>Fique em dia e ganhe pontos para o Clube!</small></ShippingStatistics>
        </div>
        <div>
          <h4>Baixar Contrato</h4>
          <LineStatistics color='#0086ef' />
          {auth.school === 'estadual-farroupilha' ? <Link download='FormaDiamond - Contrato Estadual Farroupilha' target='_blank' to={{ pathname: 'files/contrato_estadual_farroupilha.pdf' }} style={{ textDecoration: 'none', color: 'white' }}><h2>Download</h2></Link> : ''}
          {auth.school === 'mestre' ? <Link download='FormaDiamond - Contrato Mestre' target='_blank' to={{ pathname: 'files/contrato_mestre.pdf' }} style={{ textDecoration: 'none', color: 'white' }}><h2>Download</h2></Link> : ''}
          {auth.school === 'bomretiro' ? <Link download='FormaDiamond - Contrato Bom Retiro' target='_blank' to={{ pathname: 'files/contrato_bom_retiro.pdf' }} style={{ textDecoration: 'none', color: 'white' }}><h2>Download</h2></Link> : ''}
          {auth.school === 'aveline' ? <h2>Download</h2> : ''}
          <ShippingStatistics><small>Clique no botão acima e baixe o contrato!</small></ShippingStatistics>
        </div>
        {auth.signed ? (
          <>
            <div style={{ opacity: 0.2 }}>
              <h4>Assinar Contrato</h4>
              <LineStatistics color='#e6843a' />
              <h2>Assinar Agora</h2>
              <ShippingStatistics><small>Clique no botão acima para assinar o contrato!</small></ShippingStatistics>
            </div>
          </> ) : (
            <>
              <div>
                <h4>Assinar Contrato</h4>
                <LineStatistics color='#e6843a' />
                <Link to={{ pathname: auth.contract_link }} target="_blank" style={{ textDecoration: 'none', color: 'white' }}><h2>Assinar Agora</h2></Link>
                <ShippingStatistics><small>Clique no botão acima para assinar o contrato!</small></ShippingStatistics>
              </div>
            </>
          )}
      </ContentStatistics>
    </Wrapper>
  )
}
