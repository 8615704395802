import styled from 'styled-components'

interface ContentProps {
  readonly hasError: boolean;
};

export const Content = styled.div<ContentProps>`
  width: 100%;
  display: flex;
  padding-right: 10px;
  justify-content: right;

  input {
    background-color: white;
    border-radius: 25px 25px 5px 25px;
    width: 100%;
    border: 4px solid transparent;
    padding: 15px;
    transition: 0.2s;
    outline: none;
    padding-right: 5px;
    color: ${(props: any) => props.hasError ? '#f96060' : '#000'} !important;

    &:hover, &:active, &:focus-visible {
      transition: 0.2s;
      border: 4px solid #d3cece;
    }
  }
`