import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export function formatListAll(data: any) {
  const newData: any = []

  data.map((item: any) => {
    const createdAt = parseISO(item.created_at)
    const createdAtZoned = zonedTimeToUtc(createdAt, 'America/Sao_Paulo')

    newData.push({
      ...item,
      created_at: format(createdAtZoned, 'dd/MM/yyyy hh:mm')
    })

    return item
  })

  return newData
}
