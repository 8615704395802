import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Wrapper, Content, ContentStatistics, LineStatistics,
  ShippingStatistics 
} from './styles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { 
  MdFace, MdOutlineMarkAsUnread, MdInsertChartOutlined,
  MdOutlineDiamond, 
  MdHistory
} from 'react-icons/md'
import { HiOutlineTicket } from 'react-icons/hi'
import { format } from 'date-fns';
import api from '../../services/api'
import CountUp from 'react-countup';
import carroussel1 from '../../assets/diamond01.png'
import carroussel2 from '../../assets/diamond02.png'
import { AuthContext } from '../../contexts/auth';

export function PaymentsStudent() {
  const { handleLogout, auth } = React.useContext(AuthContext)
  const [count, setCount] = useState(1)
  const [daysToEnd, setDaysToEnd] = useState(0)
  const [data, setData] = useState<any>({
    totalToday: 0,
    totalWeek: 0,
    totalMonth: 0,
    totalYear: 0,
    totalConditional: 0,
    totalVarejo: 0,
    totalAtacado: 0,
    totalNumberOrders: {
      today: 0,
      week: 0,
      month: 0,
      year: 0,
      conditional: 0,
    },
    totalShippingConditional: 0,
    totalShippingMonth: 0,
    totalShippingToday: 0,
    totalShippingWeek: 0,
    totalShippingYear: 0,
    productsStock: []
  })
  const [stock, setStock] = useState<any>([])

  async function getInfos() {
    try {
      const today = format(new Date(), 'yyyy-MM-dd')
      const infos: any = await api.get(`/admin/dashboard-infos?today=${today}`)
      const stock: any = await api.get('/products')
      let productsFormatted: any = []

      infos.data.productsStock.map((product: any) => {
        const indexProd = productsFormatted.findIndex((prod: any) => prod.name === product[0]);
        
        if (indexProd !== -1) {
          productsFormatted[indexProd].qtd = productsFormatted[indexProd].qtd + product[1] * 1
        } else {
          productsFormatted.push({
            name: product[0],
            qtd: product[1] * 1
          })
        }

        return product
      })

      setStock(stock.data)
      setData({...infos.data, productsStock: productsFormatted})
      // setChartDataPie(infos.data.productsStock)
    } catch(err) {
      setData({
        totalToday: 0,
        totalWeek: 0,
        totalMonth: 0,
        totalYear: 0,
        totalConditional: 0,
        totalVarejo: 0,
        totalAtacado: 0,
        totalNumberOrders: {
          today: 0,
          week: 0,
          month: 0,
          year: 0,
          conditional: 0,
        },
        totalShippingConditional: 0,
        totalShippingMonth: 0,
        totalShippingToday: 0,
        totalShippingWeek: 0,
        totalShippingYear: 0,
        productsStock: []
      })
    }
  }

  useEffect(() => {
    if (auth.school === 'aveline') {
      let date = (new Date("Dec 14 2024")).getTime();
      let today = (new Date()).getTime();
      let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
  
      setDaysToEnd(Math.abs(Math.floor((today - date) / msDay)));
    } else if (auth.school === 'mestre') {
      let date = (new Date("Dec 20 2024")).getTime();
      let today = (new Date()).getTime();
      let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
  
      setDaysToEnd(Math.abs(Math.floor((today - date) / msDay)));  
    } else if (auth.school === 'bomretiro') {
      let date = (new Date("Dec 19 2024")).getTime();
      let today = (new Date()).getTime();
      let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
  
      setDaysToEnd(Math.abs(Math.floor((today - date) / msDay)));  
    }

    if (count === 1) {
      getInfos();
      setCount(2)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <h3>Pagamentos</h3>
      <Tabs>
        <TabList>
          <Tab>Maio</Tab>
          <Tab>Junho</Tab>
          <Tab>Julho</Tab>
          <Tab>Agosto</Tab>
          <Tab>Setembro</Tab>
          <Tab>Outubro</Tab>
          <Tab>Novembro</Tab>
          <Tab>Dezembro</Tab>
        </TabList>

        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
        <TabPanel>
          <h4>
              <span><MdHistory size={17} /></span>
              <p>
                <small>Equipe Diamond</small>
                <br />
                R$ 00,00 - <Link to='#'>PAGAR AGORA</Link>
              </p>
          </h4>
        </TabPanel>
      </Tabs>
    </Wrapper>
  )
}
