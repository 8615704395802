/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Wrapper, Content, HeaderPage, SwitchContent, ItemsList, ConditionalContent, ConditionalTotal, Errors } from './styles';
import api from '../../../services/api';
import api2 from '../../../services/api2';
import { Loading } from '../../Loading';
import { Table } from '../../Table'
import { AuthContext } from '../../../contexts/auth';
import { ButtonDashboard } from '../../ButtonDashboard';
import { MdChevronLeft, MdDoneAll, MdCheck, MdClose, MdChecklist, MdVisibility, MdReceipt, MdVisibilityOff, MdMonetizationOn, MdOutlineDelete } from 'react-icons/md';
import { InputDashboard } from '../../InputDashboard';
import { InputGroupDashboard } from '../../InputGroupDashboard';
import history from '../../../history'
import useQuery from '../../../utils/queryParams'
import Switch from 'react-switch'
import numberToReal from '../../../utils/numberToReal';
import { Select } from '../../Select'
import transformNameProducts from '../../../utils/transformNameProducts'

export function OrdersEcommerceEdit() {
  const { handleLogout } = React.useContext(AuthContext)

  let query = useQuery()
  const id = query.get('id')
  const [data, setData] = useState<any>({})
  const [dataToBody, setDataToBody] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [loadingStock, setLoadingStock] = useState(false)
  const [generateEtiqueta, setGenerateEtiqueta] = useState(true)
  const [generateNFE, setGenerateNFE] = useState(true)
  const { auth } = useContext(AuthContext)
  const [separation, setSeparation] = useState('not')
  const [showConditionals, setShowConditionals] = useState(false)
  const [conditionals, setConditionals] = useState<any>([{
    id: '',
    payd_amount: 0,
    total_amount: 0,
    created_at: ''
  }])
  const [valueToAdd, setValueToAdd] = useState<any>('')
  const [totalDebit, setTotalDebit] = useState<any>(0)
  const [totalPayed, setTotalPayed] = useState<any>(0)
  const [loadConditional, setLoadConditional] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [dataShipping, setDataShipping] = useState<any>({
    agencies : [{value: 0, text: 'Selecione uma agencia'}],
    services : [{value: 0, text: 'Selecione um serviço'}],
    cep_from: '13295073',
    selectedAgency: 0,
    selectedTransport: 0,
    selectedState: 'SP',
    etiquetaUrl: ''
  })
  const [shippingErrors, setShippingErrors] = useState<any>([])

  const columns = [
    '#',
    'Cliente',
    'Valor Pago',
    'Data do Pagamento',
    ' '
  ];

  useEffect(() => {
    if (id !== null) {
      getOrder();
    }
  }, [])

  async function getOrder() {
    setLoading(true)
    const response = await api.get(`/users/orders/list-id/${id}`).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    if (response && response.data) {
      const createdAt = parseISO(response.data.created_at)
      const createdAtZoned = zonedTimeToUtc(createdAt, 'America/Sao_Paulo')
      const date = format(createdAtZoned, 'dd/MM/yyyy hh:mm')

      setData({
        full_name: response.data.full_name,
        email: response.data.email,
        address: response.data.address,
        number: response.data.number,
        cep: response.data.cep,
        phone: response.data.customer.phone,
        cpf: response.data.cpf,
        district: response.data.district,
        city: response.data.city,
        state: response.data.uf,
        is_processed: response.data.is_processed,
        amount: numberToReal(response.data.amount ?? 0),
        amount_shipping: numberToReal(response.data.amount_shipping ?? 0),
        option_shipping: response.data.option_shipping,
        code_shipping: response.data.code_shipping,
        user: response.data.user,
        coupon: response.data.coupon,
        items: response.data.items,
        status: response.data.status,
        status_shipping: response.data.status_shipping,
        stock_type: response.data.stock_type,
        is_conditional: response.data.is_conditional,
        notes: response.data.notes,
        additional: response.data.additional,
        whatsapp_order: decodeURI(response.data.whatsapp_order),
        created_at: date,
        weight: response.data.weight,
        width: response.data.width,
        leng: response.data.leng,
        height: response.data.height,
        etiqueta_cep_sender: response.data.etiqueta_cep_sender,
        etiqueta_uf: response.data.etiqueta_uf,
        etiqueta_transport: response.data.etiqueta_transport,
        etiqueta_service: response.data.etiqueta_service,
        etiqueta_agency: response.data.etiqueta_agency,
        etiqueta_url: response.data.etiqueta_url,
        invoice_key: response.data.invoice_key
      })

      if (response.data.weight && response.data.width && response.data.leng && response.data.height && !response.data.etiqueta_url) setGenerateEtiqueta(false)
      if (response.data.weight && response.data.width && response.data.leng && response.data.height && !response.data.invoice_key) setGenerateNFE(false)

      setDataShipping({
        ...dataShipping,
        etiqueta_url: response.data.etiqueta_url
      })
      setSeparation(response.data.status)
      setDataToBody({
        ...dataToBody,
        invoice_key: response.data.invoice_key,
        is_processed: response.data.is_processed,
        is_conditional: response.data.is_conditional,
        etiqueta_url: response.data.etiqueta_url,
      })
    }

    setLoading(false)
  }

  async function getConditionals() {
    setLoadConditional(true)
    const conditionals: any = await api.get(`/users/conditional/list-all?order_id=${id}`)
    const conditionalsToSet: any = []
    let total = 0;

    conditionals.data && conditionals.data.map((conditional: any ) => {
      const createdAt = parseISO(conditional.created_at)
      const createdAtZoned = zonedTimeToUtc(createdAt, 'America/Sao_Paulo')
      const date = format(createdAtZoned, 'dd/MM/yyyy hh:mm')

      conditionalsToSet.push(
        {
          id: conditional.id,
          customer: data.full_name,
          payd_amount: 'R$ ' + numberToReal(conditional.payed_amount),
          created_at: date,
          action: <span onClick={() => handleDeleteConditional(conditional.id)}>Deletar</span>
        }
      )

      total += conditional.payed_amount
      return conditional
    })

    setTotalPayed(total)
    setTotalDebit(data.amount - total)
    setConditionals(conditionalsToSet)
    setLoadConditional(false)
  }

  useEffect(() => {
    if (data && data.is_conditional) getConditionals()
  }, [data])

  useEffect(() => {
    getAgencies()
  }, [dataShipping.selectedTransport])

  async function getAgencies() {
    const agencies: any = await api2.get(`/order/shipping/agencies?company=${dataShipping.selectedTransport}&state=${dataShipping.selectedState}`).catch(err => console.log(err))
    const formattedAgencies: any = []
    const servicesFormatted: any = []

    if (agencies.data) {
      agencies.data.map((agency: any) => {

        if (agency.name === 'CO VALINHOS 02' || agency.name === 'CO JUNDIAI 02' || agency.name === 'CO ITUPEVA 01') {
          formattedAgencies.push({
            value: agency.id,
            text: agency.name
          })
        }

        return agency
      })

      const cotation: any = await api2.post('/order/shipping/calculate', {
        from: dataShipping.cep_from,
        to: data.cep.replace('-',''),
        height: data.height,
        width: data.width,
        leng: data.leng,
        weight: data.weight
      }).catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      if (cotation.data) {
        cotation.data.carriers.map((agency: any) => {
          if (agency.id.toString() === dataShipping.selectedTransport.toString()) {
            agency.services.map((service: any) => servicesFormatted.push({ value: service.id, text: service.name }))
          }

          return agency
        })
      }

      setDataShipping({
        ...dataShipping,
        etiquetaUrl: data.etiqueta_url,
        agencies: formattedAgencies,
        services: servicesFormatted 
      })
    }
  }

  async function handleSave() {
    setLoading(true)

    const response = await api.put(`/users/orders/update/${id}`, {...dataToBody, status: dataToBody.is_conditional ? 'conditional' : dataToBody.status }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })
    
    setLoading(false)
    
    if (response && response.status === 200) history.push('/shop/my-orders')
  }
  
  async function handleOrderInSeparation() {
    setLoadingStock(true)
    const response: any = await api.put(`/users/orders/update/${id}`, {...dataToBody, status: 'separation' }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    if (response.status !== 400) {
      setSeparation('separation')
    }

    setLoadingStock(false)
    // window.location.reload();
  }

  async function handleFinishSeparation() {
    setLoadingStock(true)

    const response: any = await api.put(`/users/orders/update/${id}`, { status: 'closed' }).catch(err => {
      if (err.response.status === 401) handleLogout()
    })

    if (response.status !== 400) {
      setSeparation('closed')
      history.push('/shop/my-orders')
    }

    setLoadingStock(false)
  }

  async function handleDeleteConditional(id: any) {
    if (id !== 0) {
      const response = await api.delete(`/users/conditional?conditional_id=${id}`)

      if (response.status === 204) getConditionals()
    }
  }

  async function handleDelete() {
    if (id) {
      setLoading(true)
      await api.delete(`/users/orders/delete/${id}`).then(res => console.log(res))
        .finally(() => {
          setLoading(false)
          history.push('/shop/my-orders')
        })
    }
  }

  async function handleGenerateEtiqueta() {
    setLoading(true)
    setLoadingStock(true)
    setShippingErrors([])
    const products: any = []
    let insuranceValue = 0
    
    data.items.map((item: any) => {
      const unitVal = Math.floor(Math.random() * 60) + 1
      products.push({
        name: transformNameProducts(item.code),
        quantity: item.quantity,
        unitary_value: unitVal
      })

      insuranceValue = insuranceValue + unitVal

      return item
    })

    let options: any = {
      insurance_value: insuranceValue,
      receipt: false,
      own_hand: false,
      reverse: false,
      non_commercial: true,
      platform: "FX",
      tags: [
        {
          tag: id,
          url: null
        }
      ]
    }

    // if (dataToBody.invoice_key) {
    //   options = {
    //     insurance_value: insuranceValue,
    //     receipt: false,
    //     own_hand: false,
    //     reverse: false,
    //     non_commercial: false,
    //     invoice: {
    //       key: dataToBody.invoice_key
    //     },
    //     platform: "FX",
    //     tags: [
    //       {
    //         tag: id,
    //         url: null
    //       }
    //     ]
    //   }
    // }

    // 1 - Adicionamos ao carrinho Melhor Envio
    const dataResponseME: any = await api2.post(`/order/shipping/cart/add?id=${id}`, {
      service: dataShipping.selectedService,
      agency: dataShipping.selectedAgency,
      from: {
        name: "Raul Costas",
        phone: "11992292803",
        email: "envios0010@gmail.com",
        document: "90573542090",
        address: "Rua Carmelinda Rigone Sai",
        complement: "",
        number: "434",
        district: "Jardim Primavera",
        city: "Itupeva",
        country_id: "BR",
        postal_code: dataShipping.cep_from,
        note: ""
      },
      to: {
        name: data.full_name,
        phone: data.phone,
        email: data.email,
        document: data.cpf,
        address: data.address,
        complement: data.additional,
        number: data.number,
        district: data.district,
        city: data.city,
        state_abbr: data.state,
        country_id: "BR",
        postal_code: data.cep,
        note: ""
      },
      products,
      volumes: [
        {
          height: data.height, // Altura
          width: data.width, // Largura
          length: data.leng, // Comprimento
          weight: data.weight
        }
      ],
      options: options
    }).catch(err => {
      const errors: any = []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(err.response.data.errors)) {
        errors.push(value)
      }

      setShippingErrors(errors)
      setLoading(false)
      setLoadingStock(false)
    })

    // const orderId = responseAddCart.data.id ? responseAddCart.data.id : null
    
    // 2 - Realizamos o Checkout do nosso Carrinho
    // if (orderId) {
    //   const responseCheckout: any = await api2.post('/order/shipping/checkout', {
    //     orders: [orderId]
    //   }).catch(err => {
    //     const errors: any = []
    //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //     for (const [key, value] of Object.entries(err.response.data.errors)) {
    //       errors.push(value)
    //     }
  
    //     setShippingErrors(errors)
    //     setLoading(false)
    //     setLoadingStock(false)
    //   })

    //   // 3 - Geramos a etiqueta
    //   if (responseCheckout.status === 200) {
    //     const responseGenerate: any = await api2.post('/order/shipping/generate',  {
    //       orders: [orderId]
    //     }).catch(err => {
    //       const errors: any = []
    //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //       for (const [key, value] of Object.entries(err.response.data.errors)) {
    //         errors.push(value)
    //       }
    
    //       setShippingErrors(errors)
    //       setLoading(false)
    //       setLoadingStock(false)
    //     })

    //     // 4 - Imprimimos a Etiqueta
    //     if (responseGenerate.status === 200) {
    //       const responseEtiqueta: any = await api2.post('/order/shipping/print', {
    //         mode: "public",
    //         orders: [orderId]
    //       }).catch(err => {
    //         const errors: any = []
    //         // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //         for (const [key, value] of Object.entries(err.response.data.errors)) {
    //           errors.push(value)
    //         }
      
    //         setShippingErrors(errors)
    //         setLoading(false)
    //         setLoadingStock(false)
    //       })

    //       if (responseEtiqueta.status === 200) {
    //         setDataShipping({ ...dataShipping, etiquetaUrl: responseEtiqueta.data.url })
    //         await api.put(`/users/orders/update/${id}`, {
    //           ...dataToBody,
    //           etiqueta_url: responseEtiqueta.data.url ? responseEtiqueta.data.url : '',
    //           etiquera_cep_sender: dataShipping.cep_from,
    //           etiqueta_agency: dataShipping.selectedAgency,
    //           etiqueta_transport: dataShipping.selectedTransport,
    //           etiqueta_uf: dataShipping.selectedState,
    //           etiqueta_service: dataShipping.selectedService,
    //           etiqueta_order_id: orderId
    //         }).catch(err => {
    //           setShippingErrors(["Erro ao atualizar o pedido, mas a etiqueta foi gerada!"])
    //           setLoading(false)
    //           setLoadingStock(false)
    //           if (err.response.status === 401) handleLogout()
    //         })

    //       }
    //     }
    //   }
    // }

    if (dataResponseME && dataResponseME.data.id) setData({...data, etiqueta_url: dataResponseME.data.id})
    if (dataResponseME && dataResponseME.data.id) setDataShipping({...dataShipping, etiqueta_url: dataResponseME.data.id})
    
    setGenerateEtiqueta(true)
    setLoading(false)
    setLoadingStock(false)
  }

  async function handleAddPaymentToConditional() {
    if (valueToAdd !== 0) {
      setLoadConditional(true)
      const newConditional: any = await api.post('/users/conditional', {
        order_id: id,
        payed_amount: valueToAdd,
        total_amount: data.amount,
      })

      if (newConditional.status === 201) {
        await getConditionals()
        setValueToAdd('')
      }
      setLoadConditional(false)
    }
  }

  return (
    <Wrapper>
      <HeaderPage>
        <div>
          <h3 onClick={() => history.push('/shop/my-orders')} ><MdChevronLeft size={22}/>Pedidos</h3>
        </div>
        <div>
          {auth.stock && !generateEtiqueta && dataShipping.etiqueta_url === '' ? (
            <>
              {
                loadingStock ? <Loading /> : <ButtonDashboard onClick={() => handleGenerateEtiqueta()} color='#f08d39' text='Gerar Etiqueta' icon={<MdReceipt size={20} />} />
              }
            </>
          ): ''}
          {!auth.stock && data.status !== 'closed' ? (
            <>
              {data.is_conditional ? (
                <div>
                  <ButtonDashboard onClick={() => setShowConditionals(!showConditionals)} color='#f08d39' text='Pagamentos' icon={showConditionals ? <MdVisibilityOff size={20} /> : <MdVisibility size={20} />} />
                </div> ) : '' }
              {!showConfirmDelete && data.status === 'payd' ? (
                <div>
                  <ButtonDashboard onClick={() => setShowConfirmDelete(true)} color='#f74b4b' text='Excluir' icon={<MdOutlineDelete size={20} />} />
                </div>) : (
                  <div>
                    <ButtonDashboard onClick={() => handleDelete()} color='#f74b4b' text='Confirmar' icon={null} />
                    <ButtonDashboard onClick={() => setShowConfirmDelete(false)} color='#ff8484' text='Cancelar' icon={null} />
                  </div>
                )}
              <div>
                <ButtonDashboard onClick={() => handleSave()} color='#12a93f' text='Salvar' icon={<MdDoneAll size={20} />} />
              </div>
            </>
          ): (
            <div>
              {loadingStock ? <Loading /> : (
                <>
                  {separation === 'payd' || separation === 'conditional' ? <ButtonDashboard onClick={() => handleOrderInSeparation()} color='#215237' text='Começar Separação' icon={<MdChecklist size={20} />} /> : ''}
                  {separation === 'separation' && dataToBody.etiqueta_url === '' ? <ButtonDashboard onClick={() => handleOrderInSeparation()} color='#12a93f' text='Salvar' icon={<MdDoneAll size={20} />} /> : ''}
                  {separation === 'separation' && dataToBody.etiqueta_url ? <ButtonDashboard onClick={() => handleFinishSeparation()} color='#12a93f' text='Concluir Separação' icon={<MdDoneAll size={20} />} /> : ''}
                </>
              )}
            </div>
          )}
        </div>
      </HeaderPage>
      {!showConditionals ? (
        <Content>
          {loading ? <Loading /> :
            <>
            {shippingErrors.length > 0 ? (
              <InputGroupDashboard>
                <Errors>
                  {shippingErrors.map((error: any) => <p>Erro ao Gerar Etiqueta: {error}</p>)}
                </Errors>
              </InputGroupDashboard>
            ): ''}
              {/* <InputGroupDashboard>
                <textarea disabled={true} placeholder='Pedido Completo' defaultValue={data.whatsapp_order && data.whatsapp_order.split('text=')[1]} rows={10} onChange={(e: any) => setDataToBody({...dataToBody, whatsapp_order: e.target.value })}  />
              </InputGroupDashboard> */}
              <h5>Informações da Entrega</h5>
              <InputGroupDashboard>
                <InputDashboard copy disabled placeholder='Código' defaultValue={data.notes} />
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Nome Destinatario' defaultValue={data.full_name} onChange={(e: any) => setDataToBody({...dataToBody, full_name: e.target.value })}  />
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} maskChar={null} mask='(99) 9 9999-9999' placeholder='Telefone' defaultValue={data.phone} onChange={(e: any) => setDataToBody({...dataToBody, phone: e.target.value })}  />
              </InputGroupDashboard>
              <InputGroupDashboard>
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='E-mail' defaultValue={data.email} onChange={(e: any) => setDataToBody({...dataToBody, email: e.target.value })} />
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} maskChar={null} mask='999.999.999-99' placeholder='CPF' defaultValue={data.cpf} onChange={(e: any) => setDataToBody({...dataToBody, cpf: e.target.value })}/>
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} maskChar={null} mask='99999-999' placeholder='CEP' defaultValue={data.cep} onChange={(e: any) => setDataToBody({...dataToBody, cep: e.target.value })}/>
              </InputGroupDashboard>
              <InputGroupDashboard>
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Endereço' defaultValue={data.address} onChange={(e: any) => setDataToBody({...dataToBody, address: e.target.value })} />
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Número' defaultValue={data.number} onChange={(e: any) => setDataToBody({...dataToBody, number: e.target.value })} />
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Complemento' defaultValue={data.additional} onChange={(e: any) => setDataToBody({...dataToBody, additional: e.target.value })} />
              </InputGroupDashboard>
              <InputGroupDashboard>
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Bairro' defaultValue={data.district} onChange={(e: any) => setDataToBody({...dataToBody, district: e.target.value })} />
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Cidade' defaultValue={data.city} onChange={(e: any) => setDataToBody({...dataToBody, city: e.target.value })} />
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Estado' defaultValue={data.state} onChange={(e: any) => setDataToBody({...dataToBody, state: e.target.value })} />
              </InputGroupDashboard>
              <h5>Informações do Pedido</h5>
                {!auth.stock ? (
                  <InputGroupDashboard>
                      <InputDashboard copy disabled={data.status === 'separation' || data.status === 'closed'} placeholder='Valor do Pedido' defaultValue={data.amount} onChange={(e: any) => setDataToBody({...dataToBody, amount: e.target.value })} />
                      <InputDashboard copy disabled={data.status === 'separation' || data.status === 'closed'} placeholder='Valor do Frete' defaultValue={data.amount_shipping} onChange={(e: any) => setDataToBody({...dataToBody, amount_shipping: e.target.value })} />
                  </InputGroupDashboard>
                  ) : ''}
              <InputGroupDashboard>
                <InputDashboard copy disabled={auth.stock || data.status === 'separation' || data.status === 'closed'} placeholder='Frete Escolhido' defaultValue={data.option_shipping} onChange={(e: any) => setDataToBody({...dataToBody, option_shipping: e.target.value })} />
                <InputDashboard copy placeholder='Código de Rastreio' defaultValue={data.code_shipping} onChange={(e: any) => setDataToBody({...dataToBody, code_shipping: e.target.value})}/>
              </InputGroupDashboard>
              <InputGroupDashboard>
                <InputDashboard copy disabled={!auth.stock || data.status === 'closed'} type='number' placeholder='Peso(kg)' defaultValue={data.weight} onChange={(e: any) => setDataToBody({...dataToBody, weight: e.target.value})}/>
                <InputDashboard copy disabled={!auth.stock || data.status === 'closed'} type='number' placeholder='Altura(cm)' defaultValue={data.height} onChange={(e: any) => setDataToBody({...dataToBody, height: e.target.value})}/>
              </InputGroupDashboard>
              <InputGroupDashboard>
                <InputDashboard copy disabled={!auth.stock || data.status === 'closed'} type='number' placeholder='Largura(cm)' defaultValue={data.width} onChange={(e: any) => setDataToBody({...dataToBody, width: e.target.value})}/>
                <InputDashboard copy disabled={!auth.stock || data.status === 'closed'} type='number' placeholder='Comprimento(cm)' defaultValue={data.leng} onChange={(e: any) => setDataToBody({...dataToBody, leng: e.target.value})}/>
              </InputGroupDashboard>
              {!auth.stock ? (
                <>
                  <InputGroupDashboard>
                    <InputDashboard copy disabled placeholder='Status' defaultValue={data.status} />
                    <InputDashboard copy disabled placeholder='Status do Frete' defaultValue={data.status_shipping} />
                  </InputGroupDashboard>
                  <InputGroupDashboard>
                    <InputDashboard copy disabled placeholder='Cupom Utilizado' defaultValue={data.coupon ? data.coupon.name : ''} />
                    <Select defaultValue={data.stock_type} placeholder='Tipo do Estoque' onChange={(e: any) => setDataToBody({...dataToBody, stock_type: e.target.value })} options={[{ value: 0, text: 'VAREJO' }, { value: 1, text: 'ATACADO'}]} />
                </InputGroupDashboard>
                </>
              ) : '' }
              <h5>Itens</h5>
              <ItemsList>
                {data.items && data.items.map((item: any) => {
                  return (
                    <li>
                      <span>Cód {item.code} - {item.product.name}</span>
                      <span>Qntd: <b>{item.product.qtd}</b></span>
                    </li>
                  )
                })}
              </ItemsList>
              <InputGroupDashboard>
                <InputDashboard placeholder='Data do Pedido' defaultValue={data.created_at} disabled />
              </InputGroupDashboard>
              {!data.etiqueta_url || dataShipping.etiquetaUrl === '' ? (
                <>
                  {auth.stock ? (
                    <>
                    <h5>Informações Envio - Etiqueta</h5>
                    <InputGroupDashboard>
                        <>
                          <InputDashboard disabled={generateEtiqueta} mask='99999-999' placeholder='CEP Remetente' defaultValue={data.etiqueta_cep_sender} onChange={(e: any) => setDataShipping({...dataShipping, cep_from: e.target.value })} />
                          <InputDashboard maskChar={null} mask='aa' disabled={generateEtiqueta} placeholder='Estado' defaultValue={data.etiqueta_uf} onChange={(e: any) => setDataShipping({...dataShipping, selectedState: e.target.value})} value={dataShipping.selectedState} />
                          <InputDashboard disabled placeholder='Pedido Com Nota' defaultValue={data.invoice_key === 'true' ? 'Sim' : 'Não'}/>
                        </>
                    </InputGroupDashboard>
                    <InputGroupDashboard>
                      <Select disabled={generateEtiqueta} defaultValue={data.etiqueta_transport ?? 0} placeholder='Transportadora' onChange={(e: any) => setDataShipping({...dataShipping, selectedTransport: e.target.value })} options={[{value: 0, text: 'SELECIONE'}, { value: 1, text: 'CORREIOS' }, { value: 2, text: 'JADLOG'}]} />
                      <Select disabled={generateEtiqueta} defaultValue={data.etiqueta_service} placeholder='Serviço' onChange={(e: any) => setDataShipping({...dataShipping, selectedService: e.target.value })} options={dataShipping.services} />
                      <Select disabled={generateEtiqueta} defaultValue={data.etiqueta_agency} placeholder='Agência' onChange={(e: any) => setDataShipping({...dataShipping, selectedAgency: e.target.value })} options={dataShipping.agencies} />
                    </InputGroupDashboard>
                  </>
                  ): ''}
                </>
              ): ''}
              <InputGroupDashboard>
                {!auth.stock ? (
                  <>
                    <SwitchContent>
                      <h4>Pedido Pago</h4>
                      <Switch disabled={data.status === 'separation' || data.status === 'closed'} checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#ff0c54' onColor='#12a93f' onChange={(e) => setDataToBody({...dataToBody, status: data.status === 'payd' ? 'pending' : 'payd', is_processed: !dataToBody.is_processed})} checked={dataToBody.is_processed} defaultChecked={data.is_processed} />
                    </SwitchContent>
                    <SwitchContent>
                      <h4>Pedido Com Nota</h4>
                      <Switch checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#c1c1c1' onColor='#12a93f' onChange={(e) => setDataToBody({...dataToBody, invoice_key: dataToBody.invoice_key === 'false' ? 'true' : 'false' })} checked={dataToBody.invoice_key === 'true' ? true : false} defaultChecked={dataToBody.invoice_key === 'true' ? true : false} />
                    </SwitchContent>
                    <SwitchContent>
                      <h4>Condicional</h4>
                      <Switch disabled={data.status === 'separation' || data.status === 'closed'} checkedIcon={<MdCheck size={27} />} uncheckedIcon={<MdClose size={27} />} offColor='#c1c1c1' onColor='#12a93f' onChange={(e) => setDataToBody({...dataToBody, is_conditional: !dataToBody.is_conditional })} checked={dataToBody.is_conditional} defaultChecked={data.is_conditional} />
                    </SwitchContent>
                  </>
                ): ''}
                </InputGroupDashboard>
            </>
          }
        </Content>
      ) : (
        <Content>
          <ConditionalContent>
            <InputDashboard placeholder='Adicione um pagamento' defaultValue={() => numberToReal(0)} onChange={(e: any) => setValueToAdd(e.target.value)} value={valueToAdd} />
            <ButtonDashboard onClick={() => handleAddPaymentToConditional()} color='#1bb962' text='Add' icon={<MdMonetizationOn size={20} />} />
          </ConditionalContent>
          {loadConditional ? <Loading /> : <Table columns={columns} data={conditionals} /> }
          {loadConditional ? '' : (
            <ConditionalTotal>
              <div>
                <p>Total Devido: <span>R$ {numberToReal(totalDebit)}</span></p>
              </div>
              <div>
                <p>Total Pago: <span>R$ {numberToReal(totalPayed)}</span></p>
              </div>
            </ConditionalTotal> )}
        </Content>
      )}
    </Wrapper>
  )
}
