/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Wrapper, Content, HeaderPage } from './styles';
import { Table } from '../Table'
import { SearchDashboard } from '../SearchDashboard';
import api from '../../services/api';
import { Loading } from '../Loading';
import { AuthContext } from '../../contexts/auth';
import { formatListAll } from './format';
import { ButtonDashboard } from '../ButtonDashboard';
import { MdAdd } from 'react-icons/md';
import history from '../../history';

export function CouponsDashboard() {
  const [loading, setLoading] = useState(true)
  const { handleLogout } = React.useContext(AuthContext)

  const columns = [
    '#',
    'Nome',
    'Valor (%)',
    'Pessoa',
    'Qtd Máx',
    'Qtd Aplicada',
    'Criado Em',
  ];
  const [data, setData] = useState([{}])

  useEffect(() => {
    async function getCoupons() {
      const response = await api.get('/coupons').catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      const coupons: any = []

      response && response.data.map((account: any) => {
        coupons.push(account)

        return account
      })

      setData(formatListAll(coupons))
      setLoading(false)
    }

    getCoupons();
  }, [])

  return (
    <Wrapper>
      <HeaderPage>
        <section>
          <h3>Cupons</h3>
          <p>Lista dos cupons cadastrados</p>
        </section>
        <div>
          <ButtonDashboard onClick={() => history.push('/dashboard/coupons/edit')} text='Adicionar' icon={<MdAdd size={20} />} />
        </div>
      </HeaderPage>
      <Content>
        <SearchDashboard />
        {loading ? <Loading /> : <Table columns={columns} data={data}/> }
      </Content>
    </Wrapper>
  )
}
