import React, { useState } from 'react';
import { Wrapper, FormContent, FormGroup, Footer, ButtonElement, SellerContent } from './styles'
import { FaWhatsapp } from 'react-icons/fa'
import logo from '../../assets/formadiamond-branco.png';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Input } from '../Input'
import { LanguagesContext } from '../../contexts/languages';
import api from '../../services/api';

import pt from '../../languages/pt'
import en from '../../languages/en'
import es from '../../languages/es'

export function Contact() {
  const { language } = React.useContext(LanguagesContext)
  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  })
  const [loading, setLoading] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [contactSuccess, setContactSuccess] = useState(false)
  let currentLang = language

  switch(language) {
    case 'pt':
      currentLang = pt
      break;
    case 'es':
      currentLang = es
      break;
    case 'en':
      currentLang = en
      break;
  }

  async function sendContact() {
    if (contact.name !== '' && contact.email !== '' && contact.phone !== '' && contact.message !== '') {
      setContactError(false)
      setLoading(true)

      const response: any = await api.post('/contact', contact).catch((err: any) => {
        setContactError(true)
        setLoading(false)
      });
      
      if (response.data.id) {
        setContactSuccess(true)
        setContact({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        })
        setTimeout(() => {
          setContactSuccess(false)
        }, 4000)
      }

      setLoading(false)
    } else {
      setContactError(true)
    }
  }

  return (
    <Wrapper>
      <h2>{currentLang.titles.contact}</h2>
      <h5><MdKeyboardArrowDown size={35} /></h5>
      <FormContent>
        {contactError ? <span>Preencha todos os dados</span> : ''}
        {contactSuccess ? <h4>Sucesso! Mensagem enviada</h4> : ''}
        <Input onChange={(e: any) => setContact({ ...contact, name: e.target.value })} name="name" type="text" placeholder={currentLang.formContact.name} required value={contact.name} />
        <FormGroup>
          <Input onChange={(e: any) => setContact({ ...contact, phone: e.target.value })} mask="(99) 9999-9999" name="phone" type="text" placeholder={currentLang.formContact.phone} required value={contact.phone} />
          <Input onChange={(e: any) => setContact({ ...contact, email: e.target.value })} name="email" type="email" placeholder={currentLang.formContact.email} required value={contact.email}/>
        </FormGroup>
        <Input onChange={(e: any) => setContact({ ...contact, subject: e.target.value })} name="subject" type="text" placeholder={currentLang.formContact.subject} required value={contact.subject} />
        <textarea onChange={(e: any) => setContact({ ...contact, message: e.target.value })} id="message" rows={4} placeholder={currentLang.formContact.message} required value={contact.message} ></textarea>
        <ButtonElement disabled={loading} loading={loading} onClick={() => sendContact()} type='button'>{loading ? <AiOutlineLoading3Quarters size={16} /> : currentLang.formContact.send}</ButtonElement>
      </FormContent>
      {/* <h2>Vendedores Autorizados</h2>
      <h5><MdKeyboardArrowDown size={35} /></h5> */}
      {/* <SellerContent>
        <div>
          <h3>Boss MedTech</h3>
          <a target='_blank' href='https://wa.me/32460209677' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
        <div>
          <h3>Jhon</h3>
          <a target='_blank' href='/#' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
        <div>
          <h3>Carlos</h3>
          <a target='_blank' href='https://wa.me/5511981328669' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
        <div>
          <h3>Léo</h3>
          <a target='_blank' href='https://wa.me/554792228009' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
        <div>
          <h3>Fábio</h3>
          <a target='_blank' href='https://wa.me/5511943916849' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
        <div>
          <h3>Roger</h3>
          <a target='_blank' href='https://wa.me/554789136962' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
        <div>
          <h3>Hugo</h3>
          <a target='_blank' href='https://wa.me/5511917075946' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
        <div>
          <h3>Web Pharma</h3>
          <a target='_blank' href='https://wa.me/558186381385' rel="noopener noreferrer"><FaWhatsapp size={24} /> Contato</a>
        </div>
      </SellerContent> */}
      <Footer>
        <p>Copyright © {new Date().getFullYear()} Medtech Labs. All rights reserved.</p>
        <img src={logo} alt='Medtech Labs' />
      </Footer>
    </Wrapper>
  )
}
