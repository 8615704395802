import styled from 'styled-components'

export const Wrapper = styled.form`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;

  a {
    margin-top: 20px;

    img {
      max-width: 280px;
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }
  }

  select {
    background-color: white;
    border-radius: 25px 25px 5px 25px;
    width: 100%;
    border: 4px solid transparent;
    padding: 15px;
    transition: 0.2s;
    outline: none;
    padding-right: 5px;
    color: ${(props: any) => props.hasError ? '#f96060' : '#000'} !important;
    max-height: 58px;

    &:hover, &:active, &:focus-visible {
      transition: 0.2s;
      border: 4px solid #d3cece;
    }
  }
`

export const FormContent = styled.div`
  display: block;
  max-width: 500px;
  padding: 10px;
  margin: 0 auto;

  a {
    text-decoration: none;
    font-size: 12px;
    font-weight: 200;
    color: #ffffff;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .7;
    }

    p {
      opacity: 1;
    }
  }

  p {
    margin-bottom: 20px;
    font-size: 14px;
    opacity: .3;
  }

  h2 {
    display: block;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  input {
    width: 100%;
    margin-bottom: 25px;
  }

  button {
    width: 90%;
  }

  span, h4 {
    display: block;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    background: #f55858;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
  }

  h4 {
    background: rgb(4, 211, 97);
    color: #2b2b2c;
  }

  @media (max-width: 775px) {
    width: 100%;
  }

  textarea {
    width: 100%;
    display: flex;
    padding-right: 10px;
    background-color: white;
    border-radius: 25px 25px 5px 25px;
    width: 100%;
    border: 4px solid transparent;
    padding: 15px;
    transition: 0.2s;
    outline: none;
    padding-right: 5px;

    &:hover, &:active, &:focus-visible {
      transition: 0.2s;
      border: 4px solid #d3cece;
    }
  }

  button {
    color: #ffffff;
    background: #0086ef;
    padding: 10px;
    border-radius: 50px 50px 10px 50px;
    font-size: 14px;
    margin-top: 20px;
    transition: 0.2s;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    max-height: 45px;
    border: 3px solid #0086ef;
    transition: 0.2s;
    box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);
    margin-bottom: 15px;
    
    &:hover {
      border-radius: 10px 50px 50px 50px;
      transition: 0.2s;
      box-shadow: 0px 8px 65px -6px #0086ef;
    }
  }
`

export const FormGroup = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`

export const NewCustomerButton = styled.button`
  color: #ffffff !important;
  background: #78a5fb !important;
  padding: 10px;
  border-radius: 50px 50px 10px 50px;
  font-size: 14px;
  margin-top: 20px;
  transition: 0.2s;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-height: 45px;
  border: 3px solid #78a5fb !important;
  transition: 0.2s;
  box-shadow: 0px 8px 38px -8px rgb(120, 165, 251, .3);
  margin-bottom: 15px;
  
  &:hover {
    border-radius: 10px 50px 50px 50px;
    transition: 0.2s;
    box-shadow: 0px 8px 65px -6px #ffffff;
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;

  p {
    font-size: 12px;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    svg {
      margin-right: 10px;
    }
  }

  img {
    opacity: .5;
    width: 80px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }

`

export const LogoContent = styled.img`
  max-width: 200px;
`