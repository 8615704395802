import InputMask from 'react-input-mask';
import { Label, Content } from './styles';
import { MdContentCopy } from 'react-icons/md';

export const InputDashboard = ({ copy = false, hasError = false, disabled = false, placeholder, value, onChange, defaultValue, type = 'text', ...rest } : any) => {
  function copyLinkToClipboard(text: any) {
    navigator.clipboard.writeText(text)
  }

  return (
    <Content hasError={hasError}>
      <Label>{placeholder}</Label>
      <div>
        <InputMask disabled={disabled} type={type} defaultValue={defaultValue} onChange={onChange} value={value} placeholder={placeholder} {...rest} />
        {copy ? <MdContentCopy size={20} onClick={() => copyLinkToClipboard(value ? value : defaultValue)} /> : ''}
      </div>
    </Content>
  )
};