import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 80px;
  padding: 15px;
  flex-direction: column;
  margin-bottom: 20px;

  h3 {
    font-weight: 200;
    display: flex;
    text-align: left;
    width: 100%;
    justify-content: left;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 20px;
    align-items: center;
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      opacity: 0.5;
    }
    
    svg {
      border-radius: 50%;
      background: transparent;
      margin-right: 10px;
      transition: 0.2s;
    }
  }

  h4 {
    font-weight: 200;
    opacity: 0.7;
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;
  margin-top: 50px;

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }

`

export const ConditionalTotal = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  div {
    margin-top: 20px;
    
    &:first-child {
      span {
        color: #f25d5d;
      }
    }

    p {
      font-size: 14px;
    }

    span {
      font-size: 20px;
      color: #1bb962;
    }
  }
`

export const ConditionalContent = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;

  button {
    margin-top: 15px;
  }
`

export const Content = styled.div`
  display: block;
  grid-gap: 20px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;

  h5 {
    display: flex;
    margin-top: 30px;
    padding-left: 5px;
    text-align: left;
    margin-bottom: 20px;

    svg {
      margin-right: 10px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      padding: 5px;
    }
  }

  table {
    margin-top: 20px;
  }

  a {
    display: flex;
    background: #202024;
    padding: 20px;
    color: #ffffff;
    border-radius: 15px 15px 3px 15px;
    height: 100px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    border: 5px solid #202024;
    font-weight: bold;
    
    svg {
      margin-right: 10px;
    }
    
    &:hover {
      border-radius: 3px 15px 15px 15px;
      transition: 0.3s;
      color: #0086ef;
      border: 5px solid #0086ef;
    }
  }
`

export const Pagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;

  section {
    font-size: 14px;

    &:first-child {
      opacity: 0.7;
    }

    svg {
      width: 30px;
      height: 30px;
      background: #0086ef;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
        transition: 0.2s;
      }
    }
  }
`

interface OrderFiltersParams {
  readonly active?: any
}

export const OrderFilters = styled.ul<OrderFiltersParams>`
  margin-bottom: 10px;
  display: block;
  border-bottom: 1px solid #121212;
  background: #0f0f0f;
  border-radius: 10px;
  padding: 5px;
  justify-content: right;
  overflow: auto;
  overflow-y: hidden;
  margin: 0 auto;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 15px;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #404149;
    border-radius: 3px;
    border: 1px solid #404149 !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    background: transparent;
  }

  .active {
    background: #404149;
    opacity: 1;
  }

  li {
    list-style: none;
    display: inline-flex;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 20px;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 2px;
    transition: 0.2s;
    font-weight: 200;
    color: #ffffff;
    font-size: 12px;

    &:hover {
      transition: 0.2s;
      opacity: 1;
    }
  }
`

export const SwitchContent = styled.div`
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;

  h4 {
    font-weight: 200;
    font-size: 14px;
    margin-right: 10px;
  }
`

export const ItemsList = styled.ul`
  margin-top: 10px;

  li {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    margin-top: 10px;
    background: #1a1919;
    border-radius: 2px;

    span {
      padding: 10px;
      font-weight: 200;
      color: #ffffff;
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
      background: transparent;

      b {
        margin-left: 10px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
`

export const HeaderPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 495px) {
    display: block;
  }

  div {
    display: inline-flex;
    margin-bottom: 10px;

    span {
      display: flex;
      align-items: center;
      background: #fadd18;
      color: #000;
      padding-right: 10px;
      padding-left: 10px;
      border-radius: 5px;

      svg {
        margin-right: 10px;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        transition: 0.2s;
        opacity: 0.5;
      }

      svg {
        margin-right: 5px;
        transition: 0.2s;
      }
    }
  }
`

export const Errors = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center !important;
  margin-bottom: 10px;
  margin-top: 10px;
  background: #ff2c2c;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;

  p {
    text-align: center !important;
  }
  
`