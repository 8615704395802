import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
});

api.interceptors.request.use((config: any) => {
  const token: any = localStorage.getItem('diamond_admin_token')

  config.headers.Authorization = `Bearer ${JSON.parse(token)}`

  return config;
});

export default api;