import styled from 'styled-components'

export const Wrapper = styled.button`
  background: ${props => props.color ? props.color : '#0086ef'};
  color: #ffffff;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid ${props => props.color ? props.color : '#0086ef'};
  transition: 0.2s;
  display: flex;
  margin-right: 5px;
  max-height: 43px;

  @media (max-width: 372px) {
    i {
      display: none;
    }
  }

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: transparent;
    color: ${props => props.color ? props.color : '#0086ef'};
    transition: 0.2s;
  }
`