import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
  margin-top: 90px;
  padding: 10px;
  margin-bottom: 30px;

  h3, p {
    text-align: left;
    display: flex;
    width: 100%;
    font-weight: 200;
  }

  p {
    font-size: 14px;
    opacity: .5;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
    padding-bottom: 10px;
    margin-top: -20px;

    &:hover {
      opacity: 0.7;
    }
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 0px !important;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
`

export const TabAddPayment = styled.div`
  display: block;
  margin-top: 20px;

  button {
    display: flex;
    align-items: center;
    padding: 5px;
    background: #a1ff00;
    border: none;
    border-radius: 3px;
    font-size: 15px;

    svg {
      margin-right: 5px;
    }
  }

  :nth-child(2) {
    background-color: rgb(13 12 12);
    margin-top: 5px;
    display: flex;
    border-radius: 5px;
    padding: 5px;
  }
`

export const ContentHeader = styled.div`
  display: flex;
  margin-bottom: 40px;

  h2 {
    font-weight: 400;
    font-size: 15px;
    margin-right: 20px;
  }
`

export const Content = styled.div`
  display: block;
  grid-gap: 20px;
  margin: 0 auto;
  max-width: 100%;
  border-top: 2px solid rgb(32, 32, 36, 1);
  padding-top: 20px;

  h4 {
    text-align: left;
    margin-bottom: 15px;
  }

  table {
    margin-top: 20px;
  }

  a {
    display: flex;
    background: #202024;
    padding: 20px;
    color: #ffffff;
    border-radius: 15px 15px 3px 15px;
    height: 100px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    border: 5px solid #202024;
    font-weight: bold;
    
    svg {
      margin-right: 10px;
    }
    
    &:hover {
      border-radius: 3px 15px 15px 15px;
      transition: 0.3s;
      color: #0086ef;
      border: 5px solid #0086ef;
    }
  }
`

export const ListPayments = styled.ul`
  display: block;
  margin-top: 20px;

  li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding-top: 10px;
    padding-bottom: 10px;

    h2 {
      font-size: 15px;
      min-width: 80px;
      text-align: left;

      p {
        font-size: 15px !important;
        background: rgb(255, 56, 56);
        color: black;
        font-size: 400;
        border-radius: 10px;
        opacity: 1;
        padding-bottom: 0px;
        margin-bottom: 0px;
        text-align: left;
        justify-content: center;
      }

      small {
        font-size: 15px;
        background: rgb(161, 255, 0);
        color: black;
        font-size: 400;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    h3 {
      display: flex;
      position: relative;
      justify-content: end;
      font-size: 15px;
      margin-top: 40px;
      padding-right: 30px;
      text-align: left;

    }

    span {
      cursor: pointer;
      background-color: rgb(255, 56, 56);
      color: black;
      border-radius: 20px;
      max-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      min-width: 80px;
    }

    a {
      font-size: 15px;
      padding: 0px;
      padding-right: 10px;
      padding-left: 10px;
      height: 30px;
      margin-top: 0px;
      border: 0px;
      font-weight: 400;

      &:hover {
        border: 0px;
        opacity: 0.6;
        color: white;
      }
    }
  }
`

export const Footer = styled.div`
  width: 100%;
  max-width: 1200px;
  position: fixed;
  bottom: 10px;
  background: #000;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }
`

export const HeaderPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    display: inline-flex;
    margin-bottom: 10px;

    h3 {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        cursor: pointer;
        transition: 0.2s;
        
        &:hover {
          transition: 0.2s;
          opacity: .7;
        }
      }
    }
  }
`

export const SwitchContent = styled.div`
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;

  h4 {
    font-weight: 200;
    font-size: 14px;
    margin-right: 10px;
    padding-top: 17px;
  }

  .react-switch-bg :first-child{
    display: none;
  }

  .react-switch-bg :nth-child(2){
    display: none;
  }
`

export const TabBoleto = styled.div`
  display: flex;
  margin-top: 10px;

  label {
    display: flex;
    align-items: center;
    background-color: #3e69ff;
    padding: 5px;
    border-radius: 5px;
    color: black;
    font-size: 15px;
    cursor: pointer;
    width: 20%;
    justify-content: center;
    
    svg {
      margin-right: 5px;
    }
  }

  p {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    width: 100%;
    padding-bottom: 0;
    margin-left: 10px;
    border: 1px solid #434343;
    border-radius: 10px;
    justify-content: center;
  }
`