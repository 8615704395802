/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Wrapper, Content, Pagination, OrderFilters } from './styles';
import { MdOutlineChevronLeft, MdOutlineChevronRight, MdChevronLeft } from 'react-icons/md';
import api from '../../services/api'
import { Table } from '../Table'
import { Loading } from '../Loading'
import formatter from '../../utils/formatter'
import { format } from 'date-fns';
import history from '../../history'

export function OrdersDashboard() {
  const [loading, setLoading] = useState<any>(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterOrders, setFilterOrders] = useState('all')
  
  const [data, setData] = useState([])
  const columns = [
    '#',
    'Total',
    'Cliente',
    'Status',
    'Condicional',
    'Código de Rastreio',
    'Data do Pedido',
  ];

  useEffect(() => {
    async function getOrders() {
      try {
        setLoading(true)
        let created = ''

        if (filterOrders === 'today') created = format(new Date(), 'yyyy-MM-dd')

        const orders = await api.get(`/admin/orders/list-all?page=${page}&size=10&created_at=${created}`).finally(() => {
          setLoading(false)
        })

        const orderFormatted = formatter(orders.data.orders, false)
        const totalPagesRounded = Math.ceil(((orders.data.totalRecords * 1) / 10)) < 1 ? 1 : Math.ceil(((orders.data.totalRecords * 1) / 10))

        setTotalPages(totalPagesRounded)
        setData(orderFormatted)
      } catch(err) {
        setLoading(false)
      }
    }

    getOrders()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterOrders])

  return (
    <>
      <Wrapper>
        <h3 onClick={() => history.push('/dashboard')} ><MdChevronLeft size={22}/> Pedidos</h3>
        <OrderFilters>
          {/* <li onClick={() => setFilterOrders('today')}><span>Hoje</span></li> */}
          <li onClick={() => setFilterOrders('all')}>Geral</li>
        </OrderFilters>
        <Content>
          {data.length === 0 && !loading ? <h4>Você não tem nenhum pedido!</h4> : (
            loading ? <Loading /> : (
              <>
                <Table hasEdit={true} urlToEdit='/dashboard/orders/edit' columns={columns} data={data} />
                <Pagination>
                  <section>Página {page} de {totalPages}</section>
                  <section>{page > 1 ? <MdOutlineChevronLeft onClick={() => setPage(page - 1)} size={30} /> : '' } {page !== totalPages ? <MdOutlineChevronRight onClick={() => setPage(page + 1)} size={30} /> : ''}</section>
                </Pagination>
              </>
            )
          )}
        </Content>
      </Wrapper>
    </>
  )
}
