import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export function formatListAll(data: any) {
  const newData: any = []

  data.map((item: any) => {
    const createdAt = parseISO(item.created_at)
    const createdAtZoned = zonedTimeToUtc(createdAt, 'America/Sao_Paulo')
    let schoolTranslate

    switch(item.school) {
      case 'mestre':
        schoolTranslate = 'Mestre'
        break;
      case 'aveline':
        schoolTranslate = 'Alfredo Aveline'
        break;
      case 'estadual-farroupilha':
        schoolTranslate = 'Estadual Farroupilha'
        break;
      case 'bomretiro':
        schoolTranslate = 'Bom Retiro'
        break;
      case 'cecilia-2024':
        schoolTranslate = 'Cecília Meireles'
        break;
      case 'ifrs-caxias-2024':
        schoolTranslate = 'IFRS Caxias'
        break;
      case 'cnec-2024':
        schoolTranslate = 'CNEC'
        break;
      case 'emilio-meyer':
        schoolTranslate = 'Emílio Meyer'
        break;
      default:
        schoolTranslate = 'Escola Não Encontrada'
        break;
    }

    newData.push({
      id: item.id,
      name: item.name,
      school: schoolTranslate,
      class: item.class,
      created_at: format(createdAtZoned, 'dd/MM/yyyy hh:mm')
    })

    return item
  })

  return newData
}
