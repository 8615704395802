const es = {
  menu: {
    home: 'Inicio',
    products: 'Productos',
    contact: 'Contacto',
  },
  wppButton: 'Hablar con Vendedor',
  about: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for \'lorem ipsum\' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
  completeLine: 'Descubre nuestra línea completa',
  titles: {
    products: 'Nuestros productos',
    contact: 'Entre en Contacto',
    restrict: 'Area Restrita',
  },
  subtitles: {
    club: 'Obtenga acceso a contenido exclusivo. Suscribirse.',
    originals: 'Autentique su producto a continuación.',
  },
  buttons: {
    viewDetails: 'Ver detalles'
  },
  formContact: {
    name: 'Nombre Completo *',
    phone: 'Teléfono *',
    email: 'E-mail *',
    subject: 'Tema *',
    message: 'Mensaje *',
    send: 'Enviar',
    login: 'Entrar',
  },
  shop: {
    buy: 'Comprar',
    qty: 'Cantidad de Productos',
    myItems: 'Mis Artículos',
    menu: {
      dash: 'Mi Perfil',
      products: 'Comprar',
    },
    welcome: 'Bienvenido'
  }
}

export default es;