import React from 'react';
import { Wrapper } from './styles';

export function SearchDashboard({ ...rest }) {
  return (
    <Wrapper>
      <input placeholder='Digite a sua busca...' { ...rest } />
    </Wrapper>
  )
}
