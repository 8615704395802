import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import statusOrder from './statusOrder';
import numberToReal from './numberToReal';
import { StatusSpan } from './status-styles'

export default function formatOrders(orders: any, stock: false) {
  const ordersFormatted: any = []

  orders && orders.map((order: any) => {
    const createdAt = parseISO(order.created_at)
    const createdAtZoned = zonedTimeToUtc(createdAt, 'America/Sao_Paulo')
    let statusOrderReturn: any;

    if (stock) {
      statusOrderReturn = order.status === 'payd' || order.status === 'conditional' ? statusOrder('payd') : statusOrder(order.status);

      ordersFormatted.push({
        id: order.id,
        code: order.notes,
        client: order.full_name,
        status: <StatusSpan color={statusOrderReturn.color}>{order.status === 'payd' || order.status === 'conditional' ? 'Pronto Para Separar' : statusOrderReturn.name}</StatusSpan>,
        code_shipping: order.code_shipping,
        data: format(createdAtZoned, 'dd/MM/yyyy hh:mm')
      })
    } else {
      statusOrderReturn = statusOrder(order.status);

      ordersFormatted.push({
        id: order.id,
        code: order.notes,
        total_amount: numberToReal(order.amount + order.amount_shipping),
        client: order.full_name,
        status: <StatusSpan color={statusOrderReturn.color}>{statusOrderReturn.name}</StatusSpan>,
        conditional: order.is_conditional ? 'Sim' : 'Não',
        code_shipping: order.code_shipping,
        data: format(createdAtZoned, 'dd/MM/yyyy hh:mm')
      })
    }

    return order
  })

  return ordersFormatted
};
