import styled from 'styled-components'

export const Wrapper = styled.header`
  background: #000000;
  border-bottom: 1px solid #2c2b2b;
  position: fixed;
  display: block;
  width: 100%;
  top: 0;
  z-index: 1;
  padding: 10px;
`

export const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;

  a {
    text-decoration: none;
    color: white;

    img {
      max-width: 100px;
      transition: 0.4s;
      
      &:hover {
        transition: 0.4s;
        opacity: .7;
      }

      @media (max-width: 435px) {
        max-width: 100px;
      }
    }
  }
`

export const IconContent = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 481px) {
    svg {
      width: 15px;
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    h3 {
      font-size: 14px;
    }

    h4 {
      display: flex;
      align-items: center;
      font-weight: 200;
      font-size: 14px;
      margin-right: 10px;
    }

    &:hover {
      opacity: .8;
    }
  }
`

export const MenuContent = styled.ul`
  margin: 0;
  padding: 0;
  font-weight: 200;
  font-size: 15px;
  transition: 0.3s;

  span {
    color: #0086ef;
    text-shadow: 0 0 9px #0086ef;
  }

  li {
    list-style: none;
    margin: 10px;
    display: inline;
    margin-right: 20px;
    font-weight: 200;
    font-size: 15px;

    a {
      display: inline-flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    &:hover {
      opacity: .7;
    }
  }

  @media (max-width: 435px) {
    li {
      margin: 4px;
      font-size: 12px;
    }
  }

  @media (max-width: 860px) {
    display: none;
  }
`

export const HamburguerMenu = styled.div`
  display: none;

  svg {
    background: transparent;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
  }

  @media (max-width: 860px) {
    display: flex;
  }
`

export const HamburguerMenuContent = styled.div`
  height: 85%;
  width: 95%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 10;
  transition: 0.3s;
  border-radius: 0px 0px 20px 20px;
  justify-content: center;
  display: grid;
  vertical-align: middle;
  padding: 20px;
  text-align: center;
  align-items: center;
  top: 88px;
  
  li {
    list-style: none;
    width: 100%;
    
    a {
      text-align: center;
      color: #000;
      display: flex;
      justify-content: center;
      text-decoration: none;

      svg {
        margin-right: 10px;
      }
    }
  }
`