import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
  margin-top: 10px;

  input {
    width: 100%;
    background: rgb(32, 32, 36, .6);
    color: #ffffff;
    border: 2px solid rgb(32, 32, 36, .6);
    padding: 7px;
    border-radius: 10px;
    outline: none;
    padding-right: 10px;
    padding-left: 10px;
  }
`
