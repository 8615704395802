import styled from 'styled-components'

export const Wrapper = styled.header`
  background: #000;
  position: fixed;
  display: block;
  width: 100%;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid rgb(193 193 193 / 10%);
`

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;

  a {
    text-decoration: none;
    color: white;

    img {
      max-width: 150px;
      transition: 0.4s;
      
      &:hover {
        transition: 0.4s;
        opacity: .7;
      }

      @media (max-width: 435px) {
        max-width: 100px;
      }
    }
  }
`

export const IconContent = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 481px) {
    svg {
      width: 15px;
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;

    svg {
      &:hover {
        opacity: .8;
      }
    }
  }
`

export const MenuContent = styled.ul`
  margin: 0;
  padding: 0;
  font-weight: 200;
  font-size: 15px;
  text-align: center;

  span {
    color: #0086ef;
    text-shadow: 0 0 9px #0086ef;
  }

  li {
    list-style: none;
    margin: 10px;
    display: inline;
    margin-right: 20px;
    font-weight: 200;
    font-size: 15px;
    vertical-align: text-top;

    a {
      justify-content: center;
      display: inline-flex;
      
      svg {
        margin-right: 5px;
      }
    }

    &:hover {
      opacity: .7;
    }
  }

  @media (max-width: 886px) {
    li {
      font-size: 10px;
      svg {
        width: 30px !important;
      }
    }
  }

  @media (max-width: 435px) {
    li {
      margin: 4px;
      font-size: 10px;
    }
  }
`

export const WhatsAppButton = styled.span`
  padding: 7px;
  background: #0086ef;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.2s;
  font-size: 12px;
  box-shadow: 0px 8px 38px -8px rgb(0, 134, 239, .7);
  padding-left: 15px;
  padding-right: 15px;

  svg {
    margin-left: 10px;
  }

  &:hover {
    transition: 0.2s;
    box-shadow: 0px 8px 65px -6px #0086ef;
  }

  @media (max-width: 757px) {
    right: 0;
    position: fixed;
    bottom: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 7px;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
`
export const LanguageContent = styled.div`
  display: flex;
  background: transparent;
  padding-left: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  align-items: center;
  padding-top: 10px;
  
  img {
    width: 25px;
    margin-right: 10px;
  }

  @media (max-width: 886px) {
    left: 0;
    position: fixed;
    bottom: 0;
    margin-left: 10px;
    margin-bottom: 10px;
    background: rgba(0,0,0,.7);
    padding-bottom: 3px;
    border-radius: 25px;
  }
`

export const LanguageItem = styled.span`
  img {
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 550px) {
    img {
      opacity: 1 !important;
    }
  }
`

export const UserLogged = styled.div`
  display: flex;

  h4 {
    font-weight: 200;
    font-size: 14px;
    border-right: 1px solid rgb(193, 193, 193, 0.30);
    padding-right: 10px;
  }

  a {
    font-size: 14px;
    font-weight: 200;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }
`

export const LiUserLogged = styled.li`
  display: inline-flex;

  a {
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`