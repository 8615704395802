import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Wrapper, Content, ContentStatistics, LineStatistics, 
  ShippingStatistics } from './styles';
import { MdFace, MdOutlineMarkAsUnread, MdOutlinePaid, MdCalendarToday, MdOutlineSportsVolleyball, MdPhotoLibrary } from 'react-icons/md'
import { HiOutlineTicket } from 'react-icons/hi'
import { VscGraphLine } from 'react-icons/vsc'
import { format } from 'date-fns';
import api from '../../services/api'
import CountUp from 'react-countup';
import { AuthContext } from '../../contexts/auth';

export function Dashboard() {
  const { handleLogout } = React.useContext(AuthContext)
  const [data, setData] = useState<any>({
    totalStudents: 0,
    totalSigned: 0,
    totalNotSigned: 0,
  })

  useEffect(() => {
    async function getUsers() {
      const response = await api.get('/users').catch(err => {
        if (err.response.status === 401) handleLogout()
      })

      const users: any = []
      const signedUsers: any = []
      const notSignedUsers: any = []

      response && response.data.map((user: any) => {
        users.push({...user})

        if (user.signed) signedUsers.push({...user})
        if (!user.signed) notSignedUsers.push({...user})

        return user
      })

      setData({
        ...data,
        totalStudents: users.length,
        totalSigned: signedUsers.length,
        totalNotSigned: notSignedUsers.length,
      })
    }

    getUsers();
  }, [])

  return (
    <Wrapper>
      <ContentStatistics>
        <div>
          <h4>Total de Alunos <VscGraphLine size={17} /></h4>
          <LineStatistics color='#0086ef' />
          <h2><CountUp end={data.totalStudents} duration={1}/></h2>
          {/* <h2><CountUp end={data.totalToday} formattingFn={(value) => 'R$ ' + numberToReal(value)} duration={1}/></h2> */}
          <ShippingStatistics><small>Quantidade de alunos cadastrados</small></ShippingStatistics>
        </div>
        <div>
          <h4>Contratos Assinados <VscGraphLine size={17} /></h4>
          <LineStatistics color='#63e63a' />
          <h2><CountUp end={data.totalSigned} duration={1}/></h2>
          <ShippingStatistics><small>Quantidade de contratos assinados</small></ShippingStatistics>
        </div>
        <div>
          <h4>Contratos Inadimplentes <VscGraphLine size={17} /></h4>
          <LineStatistics color='#e63a3a' />
          <h2><CountUp end={data.totalNotSigned} duration={1}/></h2>
          <ShippingStatistics><small>Quantidade de contratos não assinados</small></ShippingStatistics>
        </div>
      </ContentStatistics>
      <h3>Ações</h3>
      <p>Dados gerais do fluxo de negócio</p>
      <Content>
        <Link to='/dashboard/users'><MdFace size={20} /> ALUNOS</Link>
        <Link to='/dashboard/payments'><MdOutlinePaid size={20} /> PAGAMENTOS</Link>
        <Link to='/dashboard/albuns'><MdPhotoLibrary size={20} /> ÁLBUNS</Link>
        <Link to='#' style={{ cursor: 'not-allowed', opacity: 0.6 }} ><MdOutlineSportsVolleyball size={20} /> PRODUTOS</Link>
        <Link to='#' style={{ cursor: 'not-allowed', opacity: 0.6 }} ><MdOutlineMarkAsUnread size={20} /> MENSAGENS</Link>
        <Link to='#' style={{ cursor: 'not-allowed', opacity: 0.6 }} ><MdCalendarToday size={20} /> CRONOGRAMA</Link>
      </Content>
    </Wrapper>
  )
}
