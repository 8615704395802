import styled from 'styled-components'

interface WrapperProps {
  readonly disabled?: boolean
}

export const Wrapper = styled.select<WrapperProps>`
  background: transparent;
  color: #000;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  border: 1px solid #c1c1c1;
  outline: none;
  width: 100%;
  min-height: 52px;
  margin-right: 10px;
  cursor: pointer;
`

export const Label = styled.span`
  font-size: 12px;
  opacity: 0.8;
`

interface ContentProps {
  readonly hasError: boolean;
};


export const Content = styled.div<ContentProps>`
  display: block;
  width: 100%;
  text-align: left;
  margin: 5px;

  div {
    display: inline-flex;
    width: 100%;
    align-items: center;

    input {
      background: #1a1919;
      color: #c1c1c1;
      padding: 10px;
      border-radius: 4px;
      display: flex;
      border: 2px solid #1a1919;
      outline: none;
      width: 100%;
      margin-right: 10px;
    }

    svg {
      opacity: 0.8;
      margin-left: -40px;
      cursor: pointer;
    }
  }

  /* div {
    display: inline-flex;
    align-items: center;

    p {
      font-size: 14px;
    }
  } */
`