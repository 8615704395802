import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1200px;
  display: block;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 80px;
  padding: 15px;
  flex-direction: column;

  h3 {
    font-weight: 200;
    display: flex;
    text-align: left;
    width: 100%;
    justify-content: left;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 20px;
    align-items: center;
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      opacity: 0.5;
    }

    svg {
      border-radius: 50%;
      background: transparent;
      margin-right: 10px;
      transition: 0.2s;
    }
  }

  h4 {
    font-weight: 200;
    opacity: 0.7;
  }
`

export const Footer = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  color: #FFFFFF;
  justify-content: space-between;
  border-top: 1px solid #252525;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  padding: 15px;
  margin-top: 50px;

  p {
    font-size: 12px;
  }

  img {
    opacity: .5;
    width: 120px;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      opacity: .8;
    }
  }

`

export const Content = styled.div`
  display: block;
  grid-gap: 20px;
  margin: 0 auto;
  width: 100%;
  border-top: 2px solid rgb(32, 32, 36, 1);
  padding-top: 20px;

  table {
    margin-top: 20px;
  }

  h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    text-align: left;
    color: #0086ef;
    border-bottom: 1px solid #0086ef;
    padding-bottom: 10px;
  }

  a {
    display: flex;
    background: #202024;
    padding: 20px;
    color: #ffffff;
    border-radius: 15px 15px 3px 15px;
    height: 100px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    border: 5px solid #202024;
    font-weight: bold;
    
    svg {
      margin-right: 10px;
    }
    
    &:hover {
      border-radius: 3px 15px 15px 15px;
      transition: 0.3s;
      color: #0086ef;
      border: 5px solid #0086ef;
    }
  }
`

export const Pagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;

  section {
    font-size: 14px;

    &:first-child {
      opacity: 0.7;
    }

    svg {
      width: 30px;
      height: 30px;
      background: #0086ef;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
        transition: 0.2s;
      }
    }
  }
`

export const OrderFilters = styled.ul`
  width: 100%;
  margin-bottom: 10px;
  display: flex;

  li {
    list-style: none;
    display: inline-flex;
    padding: 5px;
    border-bottom: 2px solid #0086ef;
    margin-right: 20px;
    cursor: pointer;
    opacity: 0.8;
    font-weight: 200 !important;
    
    span {
      opacity: 1;
      color: #0086ef;
    }

    &:hover {
      color: #0086ef;
    }
  }
`


export const HeaderPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    display: inline-flex;
    margin-bottom: 10px;

    h3 {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        cursor: pointer;
        transition: 0.2s;
        
        &:hover {
          transition: 0.2s;
          opacity: .7;
        }
      }
    }
  }
`

export const SwitchContent = styled.div`
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;

  h4 {
    font-weight: 200;
    font-size: 14px;
    margin-right: 10px;
  }
`

export const ItemsList = styled.ul`
  margin-top: 10px;

  li {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    margin-top: 10px;
    background: #1a1919;
    border-radius: 2px;

    span {
      padding: 10px;
      font-weight: 200;
      color: #ffffff;
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
      background: transparent;

      b {
        margin-left: 10px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
`