import React, { useState } from 'react'
import {
  MdFace, MdLogout, MdOutlinePaid, 
  MdOutlineMarkAsUnread, MdOutlineSportsVolleyball, 
  MdSegment, MdCalendarToday
} from 'react-icons/md'
import { Link } from 'react-router-dom';
import { Content, Wrapper, MenuContent, HamburguerMenu, HamburguerMenuContent } from './styles'
import logo from '../../assets/formadiamond-branco.png'
import { AuthContext } from '../../contexts/auth';

export function HeaderDashboard() {
  const { handleLogout } = React.useContext(AuthContext)
  const [hamburguerMenuOpen, setHamburguerMenuOpen] = useState(false)

  return (
    <Wrapper>
      <Content>
        <Link to='/dashboard'><img src={logo} alt='FormaDiamond' /></Link>
        <MenuContent>
          <li><Link to='#' onClick={() => setHamburguerMenuOpen(!hamburguerMenuOpen)}><MdSegment size={20} /> Menu</Link></li>
        </MenuContent>
        <HamburguerMenu onClick={() => setHamburguerMenuOpen(!hamburguerMenuOpen)}><MdSegment size={30} /></HamburguerMenu>
      </Content>
      {hamburguerMenuOpen ? (
          <HamburguerMenuContent>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/dashboard/users'><MdFace size={20} /> Alunos</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/dashboard'><MdOutlinePaid size={20} /> Pagamentos</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/dashboard'><MdOutlineSportsVolleyball size={20} /> Produtos</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/dashboard'><MdCalendarToday size={20} /> Cronograma</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='/dashboard/messages'><MdOutlineMarkAsUnread size={20} /> Mensagens</Link></li>
            <li onClick={() => setHamburguerMenuOpen(false)}><Link to='#' onClick={() => handleLogout()}><MdLogout size={25} style={{ opacity: .9 }} /> Sair</Link></li>
          </HamburguerMenuContent>
        ) : ''}
    </Wrapper>
  )
}
